import { toast } from "@/toasts";
import { StateUpdater, useState } from "preact/hooks";

export enum LogLevel {
    Info = "info", Error = "error", Warn = "warn"
};

export type LogEntry = {
    message: string,
    class_: string | null,
    count: number,
    time: number,
    severity: LogLevel,
};

type AddError = ((message: string, cls: string | null) => void);

export type Log = {
    logOpen: boolean,
    setLogOpen: StateUpdater<boolean>,
    entries: LogEntry[],
    error: AddError,
    warn: AddError,
    info: AddError,
};


/* State for array of log messages. Joins consecutive log entries when they use
 * equal "class_" fields, to avoid filling the log with new errors every
 * refresh interval if the server is not responding.
 */
export function useLog(): Log {

    const [logEntries, setLog] = useState([] as LogEntry[]);
    const [logOpen, setLogOpen] = useState(false);

    const createEntry = (message: string, class_: string | null, severity: LogLevel) => {
        if ((message as any).message) message = (message as any).message; // workaround for bug in non-typescript code, TODO remove.
        const log = logEntries;
        const time = Date.now();
        if (log.length > 0 && log[0].class_ && log[0].class_ == class_) {
            log[0].count = (log[0].count || 1) + 1;
            log[0].time = time;
        } else {
            log.unshift({ message, class_, severity, time, count: 1 });
            toast(severity, message, (remove) => { setLogOpen(true); remove(); });
        }
        setLog(log);
    };

    return {
        logOpen,
        setLogOpen,
        entries: logEntries,
        error: (message: string, class_: string | null) => createEntry(message, class_, LogLevel.Error),
        warn: (message: string, class_: string | null) => createEntry(message, class_, LogLevel.Warn),
        info: (message: string, class_: string | null) => createEntry(message, class_, LogLevel.Info),
    };
}