import { LogLevel } from "./models/log";

// Create the toasts container element.
export function initToasts() {
    var toastsContainerDiv = document.createElement("div");
    toastsContainerDiv.id = "toastsContainer";
    document.body.appendChild(toastsContainerDiv);
    return toastsContainerDiv;
}


/**  Open a "toast" -- a notification box in the lower right corner of the screen, 
     containing a message.*/
export function toast(type: LogLevel, message: string, action: (f: () => void) => void, timeout = 5000) {
    var container = document.getElementById("toastsContainer") || initToasts();
    var toastDiv = document.createElement("div");

    const remove: () => void = () => {
        if ((toastDiv as any).__removing) return;
        (toastDiv as any).__removing = true;
        toastDiv.classList.add("hidetoast");
        var size = toastDiv.getBoundingClientRect();
        toastDiv.style.margin = "0px";
        toastDiv.style.marginTop = `-${size.height}px`;
        toastDiv.addEventListener("transitionend", () => { toastDiv.remove(); })
    };

    toastDiv.className = `toast toast${type.toString().toLowerCase()} hidetoast`;

    // Add the element and then remove hidetoast to get entering opacity transition.
    container.prepend(toastDiv);
    toastDiv.focus(); // Needed to avoid restyle batching causing the transition not to fire.
    toastDiv.classList.remove("hidetoast");

    var icon = "";
    if (type === LogLevel.Info) icon = `<i class="toasticon toasticoninfo fas fa-info-circle"></i>`;
    if (type === LogLevel.Warn) icon = `<i class="toasticon toasticonwarn fas fa-exclamation-triangle"></i>`;
    if (type === LogLevel.Error) icon = `<i class="toasticon toasticonerror fas fa-exclamation-circle"></i>`;

    // Remove by either click action, close button, 5 sec timeout, or from returned function.

    toastDiv.innerHTML = `<div style="float:right; margin-left: 1rem;"><a href="#"><i class="fas fa-times"></i></a></div>` + icon + message;
    toastDiv.querySelector("a")?.addEventListener("click", (e) => {
        e.stopPropagation();
        remove();
    });
    if (action) toastDiv.addEventListener("click", () => action(remove));
    setTimeout(remove, timeout);
    return remove;
}