import { html } from 'htm/preact';
import { useState, useRef, useMemo, useCallback } from 'preact/hooks';

import { IconButton } from '@/utils/utils';
import { TrainGraph } from './traingraph/main';
import { StationGraph } from './stationgraph/main';
import { availableLanguages } from './translation';
import { FeedbackMenu } from './feedback';
import { printTrainGraph } from './print';
import { describeOverride } from '@/overrides';
import { useTrigger } from './utils/hooks';
import { StationRouting } from './models/stationroutingdata';
import { prepareData } from './service/backend';

const relevantSubNetworks = ["Gjøvikbanen", "Kongsvingerbanen", "Hovedbanen", "Dovrebanen"];

// Title string drawn in train graph.
function Header({ line, date, gettext, trainGraph, subNetwork, setSubNetwork }: any) {
    if (!trainGraph)
        return null;

    const dateString = date ? new Date(date).toLocaleDateString() : "?";

    let lineHtml = line;

    if (trainGraph.SubNetworks && line === null) {
        const changeSubNetwork = useCallback((e: any) => {
            const newSubNetwork = e.target.value;
            setSubNetwork(newSubNetwork);
        }, [setSubNetwork]);
        lineHtml = html`<select value=${subNetwork} onChange=${changeSubNetwork}>
            ${trainGraph.SubNetworks
                .filter((sn: any) => relevantSubNetworks.includes(sn.Name))
                .map((sn: any) => html`<option value=${sn.Name}>${sn.Name}</option>`)}
        </select>`;
    }

    return html`<div class="traingraphheader">
    ${gettext("GoTo dispatching")} — ${dateString} — ${lineHtml}
    </div>`;
}

// Collapsible sidebar menu with configuration.
function MainMenu({ menuOpen, appState }: any) {
    const gettext = appState.gettext;
    const settings = appState.userSettings;
    const checkboxes = [
        { sep: true },
        {
            name: gettext("Show train numbers"), value: settings.drawParams.trainNumbers,
            setter: (v: boolean) => settings.setDrawParams({ ...settings.drawParams, trainNumbers: v })
        },
        {
            name: gettext("Show timetable times"), value: settings.drawParams.showTimetable,
            setter: (v: boolean) => settings.setDrawParams({ ...settings.drawParams, showTimetable: v })
        },


    ];

    if (appState.userSettings.advanced && appState.userSettings.advanced !== "false") {
        checkboxes.push(...[
            { sep: true },
            {
                name: gettext("Alternative station graph style"), value: settings.drawParams.alternativeStationGraphStyle,
                setter: (v: boolean) => settings.setDrawParams({ ...settings.drawParams, alternativeStationGraphStyle: v })
            },
            {
                name: gettext("Show entry/exit times in station graph"), value: settings.drawParams.showStationEntryExitTimes ?? true,
                setter: (v: boolean) => settings.setDrawParams({ ...settings.drawParams, showStationEntryExitTimes: v })
            },
            {
                name: gettext("Draw eastbound/westbound indicator in track occupation box"), value: settings.drawParams.drawEastWestArrowInBox,
                setter: (v: boolean) => settings.setDrawParams({ ...settings.drawParams, drawEastWestArrowInBox: v })
            },
            {
                name: gettext("Draw entry/exit lines using added duration (waiting time) instead of total travel time"), value: settings.drawParams.drawEntryExitWaitTimes,
                setter: (v: boolean) => settings.setDrawParams({ ...settings.drawParams, drawEntryExitWaitTimes: v })
            },
        ]);
    }

    const checkboxhtml = checkboxes.map(({ sep, name, value, setter }) => sep !== undefined ? html`<hr />` : html`
    <label><input type="checkbox" checked=${value} onChange=${(e: any) => (setter as any)(e.target.checked)}/> ${name}</label>
  `);

    return html`
    <div class="sidebar ${!menuOpen && "sidebarclosed"}">
    <div class="sidebarcontent">
    <h1>GoTo</h1>
    <hr />
    <label for="language">${gettext("Language")}</label>
    <select id="language" value=${appState.userSettings.language} 
                          onChange=${(e: any) => appState.userSettings.setLanguage(e.target.value)}>
      ${availableLanguages.map(([l, n]) => html`<option value=${l}>${n}</option>`)}
    </select>
    <hr />
    ${checkboxhtml}
    <hr />
    </div>
      <div class="toolbarbottom">
        <img src="sintef.png" />
        <img src="banenor.png" />
      </div>
    </div>
  `;
}

// Convert tool specification object to an IconButton
const mkTool = (t: any) => {
    if (t.sep) return html`<hr />`;
    return html`<${IconButton} ...${t} onClick=${t.action} />`
};

// Draw two sets of tools on the top and bottom of the vertical toolbar element.
function ToolBar({ topTools, bottomTools }: any) {
    return html`
    <div class="toolbar">
      ${topTools.map(mkTool)}
      <div class="toolbarbottom">
      ${bottomTools.map(mkTool)}
      </div>
    </div>
  `;
}

// Show a sidebar with a list of overrides
function OverridesList({ trainGraph, visible, gettext }: any) {
    if (!visible) return html`<div class="sidebar sidebarlarge sidebarclosed"></div>`;
    var overrides = (trainGraph.AllOverrides || []).map((ov: any) =>
        html`<li class="override">${describeOverride(ov)}</li>`);
    return html`
        <div class="sidebar sidebarlarge">
            <div class="sidebarcontent">
                <h1>${gettext("Overrides")}</h1>
                <ul class="overridelist">
                    ${overrides}
                </ul>
            </div>
        </div>
     `;
}

// Draw the log with timestamps and severity category.
// With open/closed/flash movement defined in CSS styles.
function LogBar({ log, status, visible, gettext, stationRouting }: any) {
    if (!visible) return html`<div class="sidebar sidebarlarge sidebarclosed"></div>`;

    const logItems = log.map((entry: any) => {
        const time = new Date(entry.time).toLocaleString();
        return html`<li class="logentry log${entry.severity}">${time}: ${entry.message}</li>`;
    }
    );

    const statusItems = [];

    if (status && status.Errors) {
        for (const error of status.Errors) {
            statusItems.push(html`<li class="logentry logerror">${error}</li>`);
        }
    }
    if (status && status.Warnings) {
        for (const warning of status.Warnings) {
            statusItems.push(html`<li class="logentry logwarn">${warning}</li>`);
        }
    }
    if (status && status.Info) {
        for (const info of status.Info) {
            statusItems.push(html`<li class="logentry loginfo">${info}</li>`);
        }
    }

    const stationStatus = stationRouting && html`
        <div>
            <div class="logheader">Station routing solver status</div>
            <ul class="logentries">
                <li class="logentry loginfo">${(stationRouting as StationRouting)?.SolverStatus?.CurrentSolveStatus || "No solver status message received."}</li>
                ${(stationRouting as StationRouting)?.SolverStatus?.SolverLog?.map(l => html`<li class="logentry loginfo">${l}</li>`)}
                ${(stationRouting as StationRouting)?.SolverStatus?.Warnings?.map(l => html`<li class="logentry logwarn">${l}</li>`)}
                ${(stationRouting as StationRouting)?.SolverStatus?.Errors?.map(l => html`<li class="logentry logerror">${l}</li>`)}
            </ul>
        </div>`;

    return html`
        <div class="sidebar sidebarlarge">
            <div class="sidebarcontent log">
                ${stationStatus}
                <div>
                    <div class="logheader">Status</div>
                    <ul class="logentries">${statusItems}</ul>
                </div>
                <div>
                    <div class="logheader">${gettext("Message log")}</div>
                    <ul class="logentries">${logItems}</ul>
                </div>
            </div>
        </div>
     `;
}


// Style to add to connection status toolbar icon.
const connectionStatusIconStyle = (s: any) => {
    if (s.status == "ok") return "connectiongood";
    if (s.status == "serverError") return "connectionproblem";
    return "";
};

// Text to display in connection status toolbar icon tooltip.
const connectionStatusText = (s: any) => {
    if (s.status == "firstFetch") return "Connecting...";
    if (s.status == "ok") return "Connected.";
    if (s.status == "serverError") return "Communication error.";
    return s.status;
};


// If we have not seen any train graph data, and there is an server status error message,
// display this message instead of the train graph.
function MainErrorMessage({ status, trainGraph, gettext }: any) {
    if (trainGraph) return;
    if (status.Error) {
        return html`<div><div class="mainerrormessageheader">${gettext("Could not get train graph")}</div>
                    <div class="mainerrormessage">${status.Error}</div></div>`;
    }
}

// The DocumentView coordinates the train graph drawing with
// the other parts of the GUI: menu sidebar, toolbar, log window, user prompt.
// The DocumentView also decides whether to show a small log window when a message
// has recently arrived, and which tools are available in the toolbar.
export function DocumentView({ appState, serverData, status, overrideSetters }: any) {
    const log = appState.log;
    const gettext = appState.gettext;

    const [menuOpen, setMenuOpen] = useState(false);
    const [viewType, setViewType] = useState("line");
    const [overridesListOpen, setOverridesListOpen] = useState(false);
    const [feedbackMenu, setFeedbackMenu] = useState(null as any);
    const resetZoom = useRef(null);
    const [resetZoomTriggered, triggerResetZoom] = useTrigger();
    const [subNetwork, setSubNetwork] = useState<string | null>(null);

    const trainGraph = useMemo<any>(() => prepareData(serverData.TrainGraphData, serverData.Overrides || [], subNetwork),
        [serverData, subNetwork]);

    const topTools = [];
    const powerUser = appState.userSettings.advanced && appState.userSettings.advanced !== "false";

    // Open/close menu buttons
    if (menuOpen) {
        topTools.push({
            icon: 'fas fa-caret-square-left',
            enabled: true,
            action: () => { setMenuOpen(false); },
            tooltip: gettext("Close menu."),
        });
    } else {
        topTools.push({
            icon: 'fas fa-bars',
            enabled: true,
            tooltip: gettext("Open menu."),
            action: () => {
                setMenuOpen(true);
                setOverridesListOpen(false);
                log.setLogOpen(false);
            },
        });
    }

    // Toggle line/station view

    const hasStationView = !!trainGraph.StationRouting || powerUser;

    if (hasStationView) {
        topTools.push({
            icon: 'fas fa-school',
            enabled: true,
            tooltip: (viewType === "line" ? gettext("Open station view") : gettext("Open line view")) + ".",
            selected: viewType !== "line",
            action: () => {
                if (viewType === "line") {
                    setViewType("station");
                } else {
                    setViewType("line");
                }
            }
        });
    }

    // Reset zoom.
    topTools.push(...[
        { sep: true },
        {
            icon: 'fas fa-expand-arrows-alt',
            enabled: true,
            action: () => { triggerResetZoom(); if (resetZoom.current) (resetZoom as any).current(); },
            tooltip: gettext("Reset axes to default."),
            selected: false,
        }
    ]);

    // Overrides list
    if (powerUser) {
        topTools.push(...[
            {
                icon: 'fas fa-hammer',
                enabled: true,
                action: () => { setMenuOpen(false); log.setLogOpen(false); setOverridesListOpen(!overridesListOpen); },
                tooltip: html`${gettext("View overrides.")}`,
                selected: overridesListOpen,
            }
        ]);
    }

    var logStatusBadges = html`
        ${((status.Errors || []).length > 0) && html`<span class="badge badgeerror"></span>`}
        ${((status.Info || []).length > 0) && html`<span class="badge badgeinfo"></span>`}
        ${((status.Warnings || []).length > 0) && html`<span class="badge badgewarn"></span>`}
    `;

    const bottomTools = [
        {
            icon: 'fas fa-file-alt',
            enabled: true,
            selected: log.logOpen,
            action: () => { log.setLogOpen(!log.logOpen); setMenuOpen(false); setOverridesListOpen(false); },
            tooltip: `${gettext("Message log")}.`,
            badges: logStatusBadges,
        },
        {
            icon: 'fas fa-bullhorn',
            enabled: true,
            selected: feedbackMenu !== null,
            action: () => { setFeedbackMenu({}); },
            tooltip: `${gettext("Send feedback to the developers")}.`,
        },

        // TODO: online documentation
        //{
        //    icon: 'fas fa-life-ring',
        //    enabled: true,
        //    action: () => { alert("Not implemented yet."); },
        //    tooltip: gettext("User documentation."),
        //},
        {
            icon: 'fas fa-plug',
            enabled: true,
            tooltip: html`${gettext("Connection status")}: 
                                ${gettext(connectionStatusText(appState.connectionStatus))}<br />
                          ${gettext("Last update")}: ${new Date(appState.connectionStatus.time).toLocaleString()}.`,
            styles: `colorfade ${connectionStatusIconStyle(appState.connectionStatus)}`,
        },
    ];

    (bottomTools as any).push(
        {
            icon: 'fas fa-print',
            enabled: true,
            tooltip: html`Print train graph.`,
            action: () => printTrainGraph({ appState, trainGraph }),
        });
    (bottomTools as any).push(
        {
            icon: 'fas fa-sign-out-alt',
            enabled: true,
            action: () => appState.logout(),
            tooltip: gettext("Log out."),
        },
    );

    const trainGraphOrStationGraphContent = viewType === "line" ?
        (<TrainGraph {...{ appState, trainGraph, resetZoom, overrideSetters, viewType: null }} />) :
        (<StationGraph {...{ now: trainGraph.Now, appState, resetZoom: resetZoomTriggered, stationRouting: trainGraph.StationRouting }} />);

    const line = (viewType === "station") ? "Oslo S" : null;

    return html`
  <div class="maincolumns">
      <${MainMenu} menuOpen=${menuOpen} appState=${appState}/>
      <${LogBar} log=${log.entries} status=${status} visible=${log.logOpen} gettext=${appState.gettext} stationRouting=${viewType === "station" && trainGraph.StationRouting} />
      <${OverridesList} trainGraph=${trainGraph} visible=${overridesListOpen} gettext=${appState.gettext} />
      <${ToolBar} topTools=${topTools} bottomTools=${bottomTools} />
      <${Header} line=${line} date=${trainGraph && trainGraph.Now} gettext=${appState.gettext} trainGraph=${trainGraph} subNetwork=${subNetwork} setSubNetwork=${setSubNetwork}/>
      <${MainErrorMessage} status=${status} trainGraph=${trainGraph} gettext=${appState.gettext}/>
        ${trainGraphOrStationGraphContent}
      <${FeedbackMenu} ...${{ appState, feedbackMenu, setFeedbackMenu }} />
  </div>
  `;
}
