import { StationRouting, StationRoutingStation, StationRoutingTrain } from "@/models/stationroutingdata";
import { drawTrainGraph, hourLineTimes } from "@/traingraph/draw";
import { margins } from "@/traingraph/main";
import { displayTimeMinutes } from "@/utils/utils";
import * as d3 from "d3";
import { Scales } from "./main";
import { lineIsAbove, addPolygonToPath, StationGraphTrainLineRenderBatch, StationGraphTrainPolygonRenderBatch, TrainDrawing, BatchedTrainDrawings } from "./renderdata";
import { Margins } from "./scales";
import { stationName } from "./stationnames";

export type DrawInfo = {
    now: number,
    scales: Scales,
    margins: Margins,
    fontSizes: FontSizes,
    alternativeStyle: boolean,
    showStationEntryExitTimes: boolean,
    drawEastWestArrowInBox: boolean,
    drawEntryExitWaitTimes: boolean,
};

export type FontSizes = {
    medium: string,
};



export function drawStationGraph(ctx: CanvasRenderingContext2D,
    drawInfo: DrawInfo,
    stationRouting: StationRouting,
    batchedTrainDrawings: BatchedTrainDrawings,
    expandedTrain: TrainDrawing | null) {

    const trainDrawings = batchedTrainDrawings.drawings;

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const station = stationRouting.Stations[0];
    drawNowLine(ctx, drawInfo);


    // Draw tracks
    ctx.save();
    ctx.font = "bold 16px sans-serif";
    drawTracks(ctx, drawInfo, station);
    drawPlatforms(ctx, drawInfo, station);
    ctx.restore();


    // Clip to main area
    ctx.save();
    ctx.rect(drawInfo.margins.left, drawInfo.margins.top,
        drawInfo.scales.size.width - drawInfo.margins.left - drawInfo.margins.right,
        drawInfo.scales.size.height - drawInfo.margins.top - drawInfo.margins.bottom);

    ctx.clip();

    // Draw train entry/exit lines 
    ctx.save();
    drawLines(ctx, drawInfo, batchedTrainDrawings.lines);
    ctx.restore();


    // Draw trains using pre-computed path objects
    ctx.save();
    drawPolygons(ctx, drawInfo, batchedTrainDrawings.polygons);
    ctx.restore();


    // Train numbers
    ctx.save();

    ctx.fillStyle = "rgb(0,0,0)";
    ctx.textAlign = "center";
    ctx.textBaseline = "bottom";
    ctx.font = "bold 14px sans-serif";
    for (const t of trainDrawings) {
        const midX = drawInfo.scales.x(t.centerDwellTime);
        const y = t.y;
        var text = `${t.train.Number}`;
        const stop = t.train.Stops[0];

        const latenessSeconds = stop.ActualPlatformDeparture != null ?
            (stop.ActualPlatformDeparture - stop.AimedPlatformDeparture) / 1000.0 :
            stop.Lateness.EstimatedLateness;
        const latenessMinutes = Math.round(latenessSeconds / 60.0);

        ctx.save();

        if (latenessMinutes !== 0)
            text += ` (${latenessMinutes > 0 ? "+" : "-"}${Math.abs(latenessMinutes)})`;

        if (!drawInfo.alternativeStyle) {
            if (drawInfo.drawEastWestArrowInBox) {
                const westBound = lineIsAbove(t.train.Stops[0].ExitLine);
                const symbol = westBound ? "🠈" : "🠊";
                ctx.save();
                ctx.textBaseline = "middle";
                ctx.strokeStyle = "black";
                ctx.lineWidth = 4.0;
                ctx.strokeText(symbol, midX, drawInfo.scales.y(y));

                if (t.usesWestboundTrack != westBound) {
                    ctx.fillStyle = "red";
                } else {
                    ctx.fillStyle = "white";
                }

                ctx.fillText(symbol, midX, drawInfo.scales.y(y));
                ctx.restore();
            } else {
                if (lineIsAbove(t.train.Stops[0].ExitLine)) {
                    text = "🠈 " + text;
                } else {
                    text += " 🠊";
                }
            }
        }

        const differentPlannedTrack = stop.PlannedTrackId && stop.AimedTrackId && stop.PlannedTrackId != stop.AimedTrackId;
        const differentActualTrack = stop.ActualTrackId && stop.AimedTrackId && stop.ActualTrackId != stop.AimedTrackId;

        if (latenessSeconds > 2 * 60 || differentPlannedTrack || differentActualTrack) {
            const latenessColor = "red";
            ctx.fillStyle = latenessColor;
        }

        if (differentPlannedTrack || differentActualTrack)
            text += ` !`;

        ctx.fillText(text, midX, drawInfo.scales.y(y - 0.20 - 0.05));
        ctx.restore();
    }
    ctx.restore();


    if (expandedTrain != null) {
        ctx.save();
        drawTimetable(ctx, drawInfo, expandedTrain);
        drawExpandedTrain(ctx, drawInfo, expandedTrain);
        ctx.restore();
    }

    ctx.restore(); // clip to main area


    // Hour lines
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0.25)";
    for (const d of hourLineTimes(drawInfo.scales.x)) {
        ctx.beginPath();
        d3.line().context(ctx)([[drawInfo.scales.x(d) + 0.5, drawInfo.scales.y.range()[0] + 0.5], [drawInfo.scales.x(d) + 0.5, drawInfo.scales.y.range()[1] + 0.5]]);
        ctx.stroke();
    }
    ctx.restore();

    // Draw left gutter
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(drawInfo.margins.left, 0);
    ctx.lineTo(drawInfo.margins.left, drawInfo.scales.size.height);
    ctx.strokeStyle = "gray";
    ctx.stroke();
    ctx.restore();
}

function drawTimetable(ctx: CanvasRenderingContext2D, drawInfo: DrawInfo, train: TrainDrawing) {
    let m = mkTransformationMatrix(drawInfo);
    const path = new Path2D();
    addPolygonToPath(path, train.timetablePolygon, true);

    const transformedPath = new Path2D();
    transformedPath.addPath(path, m);
    ctx.strokeStyle = "DarkOrange";
    ctx.lineWidth = 3;
    ctx.setLineDash([10, 5]);
    ctx.stroke(transformedPath);
}

function drawExpandedTrain(ctx: CanvasRenderingContext2D, drawInfo: DrawInfo, train: TrainDrawing) {
    ctx.fillStyle = "rgb(0,0,0)";
    const midX = drawInfo.scales.x(train.centerTime);
    const y = train.y;

    const strokeAndFill = (t: string, x: number, y: number) => {
        ctx.strokeText(t, x, y);
        ctx.fillText(t, x, y);
    };
    const padText = (t: string) => "  " + t + "  ";

    // Train number above
    // ctx.textAlign = "center";
    // ctx.textBaseline = "bottom";
    // ctx.font = "bold 14px sans-serif";
    // ctx.fillText(train.train.Number, midX, drawInfo.scales.y(y - 0.30 - 0.05));

    //  Service and type below
    // ctx.textBaseline = "top";
    // ctx.font = "14px sans-serif";
    // ctx.fillText(`${train.train.ServiceName} ${train.train.KindTypeAndOperator}`, midX, drawInfo.scales.y(y + 0.15 + 0.05));

    if (train.entryTextPt && train.exitTextPt) {
        if (drawInfo.alternativeStyle) {
            ctx.textBaseline = "middle";
            ctx.textAlign = "right";
            // Origin station
            ctx.fillText(stationName(train.train.OriginStationId ?? ""),
                drawInfo.scales.x(train.entryTextPt[0]),
                drawInfo.scales.y(train.entryTextPt[1]));

            ctx.textAlign = "left";
            // Destination station
            ctx.fillText(stationName(train.train.DestinationStationId ?? ""),
                drawInfo.scales.x(train.exitTextPt[0]),
                drawInfo.scales.y(train.exitTextPt[1]));
        } else {
            ctx.font = "14px sans-serif";
            ctx.textBaseline = "middle";
            ctx.textAlign = "right";
            ctx.strokeStyle = "white";
            ctx.lineWidth = 4.0;

            strokeAndFill(padText(stationName(train.train.OriginStationId ?? "")),
                drawInfo.scales.x(train.entryTextPt[0]),
                drawInfo.scales.y(train.entryTextPt[1]));

            ctx.textAlign = "left";
            strokeAndFill(padText(stationName(train.train.DestinationStationId ?? "")),
                drawInfo.scales.x(train.exitTextPt[0]),
                drawInfo.scales.y(train.exitTextPt[1]));
        }
    }
}

function drawPlatforms(ctx: CanvasRenderingContext2D, drawInfo: DrawInfo, station: StationRoutingStation) {
    ctx.strokeStyle = "rgb(64,64,64)";
    ctx.fillStyle = "rgb(192,192,192)";
    ctx.lineWidth = 2;
    if (station.Id == "NO.OSL") {
        const platforms = [1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
        for (const y of platforms) {
            ctx.beginPath();
            const y1 = drawInfo.scales.y(y - 1 + 0.4);
            const y2 = drawInfo.scales.y(y - 1 + 0.6);
            ctx.rect(0.5 * drawInfo.margins.left, y1, 0.5 * drawInfo.margins.left - 5, y2 - y1);
            ctx.stroke();
            ctx.fill();
        }
    }
}



function drawTracks(ctx: CanvasRenderingContext2D, drawInfo: DrawInfo, station: StationRoutingStation) {
    ctx.strokeStyle = "rgba(0,0,0,0.5)";


    station.TrackNames.map((t, i) => {
        ctx.beginPath();
        d3.line().context(ctx)([
            [drawInfo.margins.left, drawInfo.scales.y(i) + 0.5],
            [drawInfo.scales.size.width - drawInfo.margins.right + 0.5, drawInfo.scales.y(i) + 0.5]]);
        ctx.stroke();

        var stationNameX = drawInfo.margins.left - 5;
        ctx.textBaseline = "middle";
        ctx.textAlign = "right";
        var text = `${t}`;
        ctx.fillText(text, stationNameX, drawInfo.scales.y(i));
    });
}

function drawPolygons(ctx: CanvasRenderingContext2D, drawInfo: DrawInfo, renderBatches: StationGraphTrainPolygonRenderBatch[]) {

    let m = mkTransformationMatrix(drawInfo);

    for (const batch of renderBatches) {
        ctx.globalAlpha = batch.weak ? 0.5 : 1.0;
        const transformedPath = new Path2D();
        transformedPath.addPath(batch.path, m);

        if (batch.fill) {
            ctx.fillStyle = batch.color;
            ctx.fill(transformedPath);
        } else {
            ctx.fillStyle = "black";
            ctx.lineWidth = 2;
            ctx.stroke(transformedPath);
        }
    }
}

function drawLines(ctx: CanvasRenderingContext2D, drawInfo: DrawInfo, renderBatches: StationGraphTrainLineRenderBatch[]) {

    let m = mkTransformationMatrix(drawInfo);

    for (const batch of renderBatches) {
        const transformedPath = new Path2D();
        transformedPath.addPath(batch.path, m);

        ctx.save();

        ctx.strokeStyle = batch.color;
        if (batch.width != null) {
            ctx.lineWidth = batch.width;
        }
        if (batch.isActual === false) {
            ctx.globalAlpha = 0.5;
        }

        ctx.stroke(transformedPath);

        ctx.restore();
    }
}

function mkTransformationMatrix(drawInfo: DrawInfo) {
    const xrange = drawInfo.scales.x.range();
    const xdomainDate = drawInfo.scales.x.domain();
    const xdomain = [xdomainDate[0].getTime(), xdomainDate[1].getTime()];
    const yrange = drawInfo.scales.y.range();
    const ydomain = drawInfo.scales.y.domain();

    // the scale (x and y separately) function's domain is the data
    // for x, the time range (e.g. 01:00 - 12:00 on 19/12/2019),
    // and for y the station range(e.g. 0 - 30).
    // The scale function's range is the screen's pixel size (after adjusting for DPI so
    // that each pixel has similar size on different machines).
    //
    // we are looking for a translation/scaling matrix of the form
    // [ kx  0 tx ]
    // [  0 ky ty ]
    // [  0  0  1 ]
    //
    // that takes s.domain()[i] into s.range()[i], for s in {xscale, yscale} and i in {0, 1}.
    //
    // this means that
    //  kx = (xrange[1] - xrange[0]) / (xdomain[1] - xdomain[0]);
    //  ky = ...
    //
    // and the translation
    //  tx = xrange[0] - kx*xdomain[0]
    //  ty = ...
    const kx = (xrange[1] - xrange[0]) / (xdomain[1] - xdomain[0]);
    const xRefDomain = xdomain[0] - drawInfo.now;
    const tx = xrange[0] - kx * xRefDomain;
    const ky = (yrange[1] - yrange[0]) / (ydomain[1] - ydomain[0]);
    const ty = yrange[0] - ky * ydomain[0];

    let m = document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGMatrix();
    m.a = kx; m.b = 0;
    m.c = 0; m.d = ky;
    m.e = tx; m.f = ty;
    const matrix = { a: kx, b: 0.0, c: 0.0, d: ky, e: tx, f: ty };
    return m;
}

function drawNowLine(ctx: CanvasRenderingContext2D, draw: DrawInfo) {
    // Now line
    ctx.save();
    ctx.lineWidth = 2;
    ctx.strokeStyle = "rgba(0,0,255,0.5)";
    ctx.fillStyle = "rgba(0,0,255)";
    ctx.beginPath();
    d3.line().context(ctx)([[draw.scales.x(draw.now) + 0.5, draw.margins.top + 0.5],
    [draw.scales.x(draw.now) + 0.5, draw.scales.size.height - draw.margins.bottom + 0.5]]);
    ctx.stroke();
    ctx.font = draw.fontSizes.medium;
    ctx.textBaseline = "top";
    const offsetTop = 5;
    ctx.fillText(displayTimeMinutes(new Date(draw.now)), draw.scales.x(draw.now) + 5 + 0.5, draw.margins.top + offsetTop + 0.5);
    ctx.restore();
}