import { margins, mkBaseScales } from './traingraph/main.js';
import { drawTrainGraph } from './traingraph/draw.js';
import * as d3 from 'd3';

// Open a print dialog for printing the train graph.
// The printed output is similar in style to "Daglige rutegrafer" on Bane NOR's web page.
export function printTrainGraph({ appState, trainGraph }: any) {
    const gettext = appState.gettext;
    const line = trainGraph.RailwayNetworkName;
    const pages = createPages(trainGraph, appState.userSettings.drawParams);
    if (!pages)
        throw "";
    const range = `${new Date(pages.startTime).getHours()}:00-${new Date(pages.endTime).getHours()}:00`;
    const title = `${gettext("GoTo dispatching")} — ${line} — ${new Date(trainGraph && trainGraph.Now).toLocaleDateString()}`;
    const doc = `<!DOCTYPE html>
    <html>
    <head>
        <title>${title}</title>
        <style>
        @media print {
            @page { 
                margin: 4mm;
                size: A4 portrait; 
                height: 210mm;
                width: 297mm;
            }
            html, body 
            {
                font-family: sans-serif;
                text-align: center;
            }
            img { 
                margin-top: 1.5em;
                max-width: 98%;
            }
            #headercontainer {
                position: fixed; top: 0; width: 100%;
            }
            #headertext {
                width: auto; margin: 0 auto;
            }
        }
        </style>
    </head>
    <body>
        <div id="headercontainer"><div id="headertext">${title}</div></div>
        ${pages.imgs.map(dataUrl => `<img src="${dataUrl}">`)}
    </body>
    </html>
    `;
    printDoc(doc);
}

// Builds the document in the given string in a hidden IFrame in the current document,
// opens the print dialog for this document and then deletes the IFrame.
function printDoc(doc: string) {

    var iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.onload = function () {
        if (!iframe.contentDocument)
            throw "";

        iframe.contentDocument.write(doc);
        iframe.contentDocument.addEventListener('load', () => {
            if (!iframe.contentWindow || !iframe.parentNode)
                throw "";

            iframe.contentWindow.focus();
            //iframe.contentWindow.print();
            // https://stackoverflow.com/a/19639241
            iframe.contentWindow.document.execCommand('print', false, undefined);
            iframe.parentNode.removeChild(iframe);
        }, true);
    };

    document.body.appendChild(iframe);

    //var printWin = window.open('', '', 'width=340,height=260');
    //printWin.document.open();
    //printWin.document.write(windowContent);
    //printWin.document.close();
    //printWin.document.addEventListener('load', function () {
    //    printWin.focus();
    //    printWin.print();
    //    printWin.document.close();
    //    printWin.close();
    //}, true); 
}


function createPages(trainGraph: any, drawParams: any) {
    const pages = [];

    const hoursBefore = 4;
    const hoursAfter = 2;
    const hoursStep = 6;

    const hourFactor = 1000 * 60 * 60;
    const startTime = Math.round(trainGraph.Now / hourFactor) * hourFactor - hoursBefore * hourFactor;
    const timeStep = hoursStep * hourFactor;
    const endTime = startTime + (hoursBefore + hoursAfter) * hourFactor;

    for (var time = startTime; time < endTime; time += timeStep) {
        const ratio = 1.40;
        const pxSize = 2000;
        const size = { width: pxSize, height: pxSize * ratio };
        var canvas = document.createElement("canvas");
        canvas.width = size.width;
        canvas.height = size.height;
        var context = canvas.getContext("2d");
        var baseScales = mkBaseScales(trainGraph, size, margins);
        if (!baseScales)
            return;
        const graphMargins = { ...margins, left: margins.left * 1.5, right: margins.right * 1.5 };
        const xScale = d3
            .scaleTime()
            .domain([time, time + timeStep])
            .range([graphMargins.left, size.width - graphMargins.right]);

        drawTrainGraph({
            ctx: context,
            margins: graphMargins,
            trainGraph: trainGraph,
            scales: { x: xScale, y: baseScales.y0, size },
            drawParams: { ...drawParams, drawCanvasHours: true, drawFrame: true },
            fontSizes: { "small": "20px", "medium": "22px", "large": "26px" },
            hoveredIndex: undefined,
            canHoverVisits: undefined,
            hoveredStation: undefined,
            selectedVisits: undefined,
            visits: undefined,
        });

        var dataUrl = canvas.toDataURL();
        pages.push(dataUrl);
    }

    return { imgs: pages, startTime, endTime };
}