import { StationRoutingTrain } from "@/models/stationroutingdata";

const fallbackTrainColor = "rgb(54,46,46)";

export function trainColor(train: StationRoutingTrain): string {
    // Goods trains
    if (train.KindTypeAndOperator?.match("Gt") || train.KindTypeAndOperator?.match("EGt")) {
        return "rgb(170,85,0)";
    }

    // Service trains
    if (train.KindTypeAndOperator?.match("At"))
        return "blue";

    // Passenger trains
    return fallbackTrainColor;
}


export function trainColorAlternativeStyle(train: StationRoutingTrain): string {

    // Goods trains
    if (train.KindTypeAndOperator?.match("Gt") || train.KindTypeAndOperator?.match("EGt")) {
        return "rgb(170,85,0)";
    }

    // Service trains
    if (train.KindTypeAndOperator?.match("At"))
        return "blue";

    // Linjekart for lokaltog på østlandet
    //  https://www.vy.no/globalassets/vy.no/filer-no/linjekart/nye-linjekart/linjekart-lokaltog-ostlandet.pdf

    for (const service of (train.ServiceName || "").split("/")) {
        if (service == "R10" || service == "R11")
            return "rgb(158,26,42)";
        if (service == "R20")
            return "rgb(132,59,147)";
        if (service == "R30" || service == "R30x")
            return "rgb(14,125,62)";
        if (service == "L1")
            return "rgb(215,51,140)";
        if (service == "L2" || service == "L2x")
            return "rgb(85,194,239)";
        if (service == "L3")
            return "rgb(132,196,66)";
        if (service == "L12")
            return "rgb(228,30,36)";
        if (service == "L13")
            return "rgb(244,137,44)";
        if (service == "L14")
            return "rgb(252,194,42)";
        if (service == "L21")
            return "rgb(132, 106, 174)";
        if (service == "L22")
            return "rgb(26,99,176)";
        if (service == "F1" || service == "F1x" || service == "F2")
            return "rgb(108,111,113)";
    }


    // Fallback: black-ish
    return fallbackTrainColor;
}
