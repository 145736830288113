export function translate(lang: string, msg: string): string {
    if (lang !== "no") return msg;
    if (!(msg in norwegian)) {
        for (var f of translateFunctions) {
            msg = f(msg);
        }
        return msg;
    }
    return norwegian[msg];
}

export const availableLanguages = [["no", "Norsk"], ["en", "English"]];

const norwegian: Record<string, string> = {
    // general
    "Unknown document type": "Ukjent dokumenttype",
    "Message not available in target language": "Meldingen kunne ikke gis på ønsket språk",

    // server messages
    "Invalid username or password.": "Ugyldig brukernavn eller passord.",
    "The username or password is incorrect": "Ugyldig brukernavn eller passord",

    // login.js
    "Login failed": "Feil ved innlogging",
    "Server message": "Melding fra tjener",
    "GoTo dispatching": "GoTo beslutningsstøtte for togledelse",
    "Username": "Brukernavn",
    "Password": "Passord",
    "Log in": "Logg inn",

    // poll.js
    "Could not reach server.": "Klarte ikke å kontakte tjener.",
    "HTTP error": "HTTP-feil",
    "Not Found": "ikke funnet",
    "Internal Server Error": "intern feil hos tjener",
    "Error in train graph data": "Feil i toggraf",
    "Error fetching train graph data": "Feil ved henting av toggraf-data",

    // documentview.js
    "Could not get train graph": "Klarte ikke å hente toggraf",
    "Pan view axes.": "Panorér grafvisningen.",
    "Open menu.": "Åpne meny.",
    "Close menu.": "Lukk meny.",
    "Print train graph.": "Utskrift av rutegraf.",
    "View overrides.": "Vis overstyringer.",
    "User documentation.": "Åpne brukerdokumentasjon.",
    "Last update": "Sist oppdatert",
    "Log out.": "Logg ut.",
    "Reset axes to default.": "Tilbakestill akser.",
    "Create new track closure override.": "Lag ny stenging.",
    "Language": "Språk",
    "Enable override tools": "Vis verktøy for situasjonsoppdateringer.",
    "unsupported": "ikke støttet",
    "Message log": "Systemmeldinger",
    "Send feedback to the developers": "Sende tilbakemelding til utviklerne",
    "Open station view": "Gå til stasjonsvisning",
    "Open line view": "Gå til linjevisning",

    // overrides
    "Overrides": "Overstyringer",

    "Show train numbers": "Vis tognummer",
    "Show minutes in graph": "Vis minutter i toggraf",
    "Show seconds in graph": "Vis sekunder i toggraf",
    "Show track in stations": "Vis sporvalg på stasjoner",
    "Show timetable times": "Vis ruteplantider",
    "Show actual times": "Vis reelle tider",
    "Show planned times": "Vis foreslåtte tider",
    "Alternative station graph style": "Alternativ stil på stasjonsdiagram",
    "Show entry/exit times in station graph" : "Vis innkjør/utkjør-tidspunkter i stasjonsdiagram",
    "Draw eastbound/westbound indicator in track occupation box" : "Vis østgående/vestgående-indikator i sporbruksboks",
    "Draw entry/exit lines using added duration (waiting time) instead of total travel time": "Vis innkjør/utkjør som tilleggstid (ventetid) i stedet for total kjøretid",

    "Connection status": "Forbindelse",
    "Connecting...": "Kobler til...",
    "Connected.": "Tilkoblet.",
    "Communication error.": "Tilkobling feilet.",

    // Feedback
    "Thank you for submitting feedback": "Takk for at du sender tilbakemelding",
    "Name/email": "Navn/epost",
    "Comment": "Kommentar",
    "Attached screenshot": "Vedlagt skjermbilde",

    // Server messages
    "There is no graph because the train data provider has not been initialized": "Ingen toggraf tilgjengelig fordi mottaker for sanntidsdata ikke er startet.",
    "No graph yet -- the train data provider is being initialized": "Ingen toggraf tilgjengelig ennå, mottaker for sanntidsdata starter opp...",
    "The lastest changes to the overrides are not yet reflected in the plan.": "De siste endringene i toggrafen er ikke oppdatert ennå.",
    "There is 1 active override.": "Det finnes 1 aktiv overstyring",

    // Warnings
    "No real-time information": "Ingen sanntidsinformation fra TIOS",
    " yet": "",
    "since": "siden",
    "The train was not included for planning.": "Toget er ikke inkludert i planlegging.",
    "Actual train times are estimated.": "Faktiske togtider er estimerte."
};

const translateFunctions = [
    (s: string) => s.replace("Assuming", "Antar"),
    (s: string) => s.replace(/There is (\d+) active overrides./, (match, g1) => `Det finnes ${g1} aktive overstyringer.`),
    (s: string) => s.replace("The last solve, started at", "Siste beregning, startet "),
    (s: string) => s.replace(", failed with message:", ", feilet med meldingen:"),
    (s: string) => s.replace("Problem with the data for train", "Klarte ikke å tolke sanntidsdata for tog"),
    (s: string) => s.replace("Unknown length for train", "Ukjent toglengde for tog"),

    (s: string) => s.replace(/Error: trains (.*) and (.*) cannot be fixed at station (.*). Change meeting location/,
        (match, g1, g2, g3) => `Tog ${g1} og ${g2} kan ikke møtes ved ${g3}. Endre møtested`),
];
