import { StationRouting, StationRoutingStop, StationRoutingTrain } from "@/models/stationroutingdata";
import { trainColor, trainColorAlternativeStyle } from "./coloring";
import { DrawInfo } from "./draw";

// This structure is a preparation of the paths that will be 
// drawn to the canvas. They are given in units of:
//  - relative time on the x-axis
//  - unit track separation on the y-axis (track 1 is at y=1, track 2 at y=2, etc.)
export type StationGraphTrainPolygonRenderBatch = {
    color: string,
    weak: boolean,
    path: Path2D,
    fill: boolean,
};

export type StationGraphTrainLineRenderBatch = {
    color: string,
    width: number | null,
    isActual: boolean | null,
    path: Path2D,
};

type TrainDrawingWithActualAndPlanned = TrainDrawing & {
    polygonActual: [x: number, y: number][] | null,
    polygonPlanned: [x: number, y: number][] | null,
    entry: [x: number, y: number][],
    exit: [x: number, y: number][],
};

export const addPolygonToPath = (path: Path2D, polygon: [number, number][], closed: boolean) => {
    path.moveTo(polygon[0][0], polygon[0][1]);
    for (var i = 1; i < polygon.length; i += 1) {
        path.lineTo(polygon[i][0], polygon[i][1]);
    }
    if (closed) {
        path.closePath();
    }
};

export function mkLineRenderBatches(referenceDate: number, drawings: TrainDrawingWithActualAndPlanned[]): StationGraphTrainLineRenderBatch[] {
    const path = new Path2D();
    for (const drawing of drawings) {
        path.moveTo(drawing.entry[0][0] - referenceDate, drawing.entry[0][1]);
        path.lineTo(drawing.entry[1][0] - referenceDate, drawing.entry[1][1]);
        path.moveTo(drawing.exit[0][0] - referenceDate, drawing.exit[0][1]);
        path.lineTo(drawing.exit[1][0] - referenceDate, drawing.exit[1][1]);
    }

    return [{ path, color: "black", width: null, isActual: null }];
}


export function mkPolygonRenderBatchesAlternativeStyle(drawings: TrainDrawingWithActualAndPlanned[]): StationGraphTrainPolygonRenderBatch[] {
    const styleMap: Map<string, Path2D> = new Map();
    const styleNameMap: Map<string, [string, boolean, boolean]> = new Map();
    for (const drawing of drawings) {
        const polygons: [[number, number][] | null, boolean, boolean][] = [[drawing.polygonActual, true, true], [drawing.polygonActual, true, false], [drawing.polygonPlanned, false, true]];
        for (const [polygon, actual, closed] of polygons) {
            if (polygon == null)
                continue;

            var key = `${drawing.color.toUpperCase()} ${actual} ${closed}`;
            var path = styleMap.get(key);
            if (!path) {
                path = new Path2D();
                styleMap.set(key, path);
                styleNameMap.set(key, [drawing.color, actual, closed]);
            }

            addPolygonToPath(path, polygon, closed || polygon.length >= 12);

        }
    }

    return Array.from(styleMap.entries()).map(([key, path]) => ({
        color: styleNameMap.get(key)?.[0] ?? "black",
        path,
        weak: styleNameMap.get(key)?.[1] ? false : true,
        fill: styleNameMap.get(key)?.[2] ?? false,
    }));
}

export type TrainDrawing = {
    train: StationRoutingTrain,
    boundingBox: [x: number, y: number][],
    timetablePolygon: [x: number, y: number][],
    centerTime: number,
    centerDwellTime: number,
    color: string,
    y: number,
    entryTextPt: [x: number, y: number] | null,
    exitTextPt: [x: number, y: number] | null,
    isActual: boolean,
    usesWestboundTrack: boolean,
};

export type BatchedTrainDrawings = {
    drawings: TrainDrawing[],
    referenceDate: number,
    polygons: StationGraphTrainPolygonRenderBatch[],
    lines: StationGraphTrainLineRenderBatch[],
};

export function mkTrainDrawingsAlternativeStyle(referenceDate: number, stationRouting: StationRouting, stationId: string): BatchedTrainDrawings {
    const trackIdsToPosition = TrackIdsToPosition(stationRouting);
    const trainShapes = Array.from(iterTrains(stationRouting, stationId)).map(t => {
        const shape = mkTrainShapeAlternativeStyle(referenceDate, trackIdsToPosition, t.stop, t.train);
        const color = trainColorAlternativeStyle(t.train);
        return { ...shape, color };
    });

    const polygons = mkPolygonRenderBatchesAlternativeStyle(trainShapes);
    const lines = mkLineRenderBatches(referenceDate, trainShapes);

    return { drawings: trainShapes, referenceDate, polygons, lines };
}

const timetableOffset = 0.05;

export function mkTrainDrawings(referenceDate: number, stationRouting: StationRouting, stationId: string, drawInfo: DrawInfo | null): BatchedTrainDrawings {
    const trackIdsToPosition = TrackIdsToPosition(stationRouting);

    const dwellPolygonWidth = 0.4;
    const entryExitLineOffset = 0.0;
    const entryExitLineWidth = 5.0;

    const polygonsStyleMap: Map<string, StationGraphTrainPolygonRenderBatch> = new Map();
    const linesStyleMap: Map<string, StationGraphTrainLineRenderBatch> = new Map();

    function getLine(color: string, isActual: boolean) {
        const lineKey = `${color.toUpperCase()} ${isActual}`;
        var path = linesStyleMap.get(lineKey)?.path;
        if (!path) {
            path = new Path2D();
            linesStyleMap.set(lineKey, { path, color, isActual, width: entryExitLineWidth });
        }
        return path;
    }

    function getPolygon(color: string, isActual: boolean, fill: boolean) {
        const key = `${color.toUpperCase()} ${isActual} ${fill}`;
        var path = polygonsStyleMap.get(key)?.path;
        if (!path) {
            path = new Path2D();
            polygonsStyleMap.set(key, { path, color, fill, weak: !isActual });
        }
        return path;
    }

    const drawings: TrainDrawing[] = [];

    for (const { train, stop } of Array.from(iterTrains(stationRouting, stationId))) {
        const color = trainColor(train);
        const drawParams = drawParameters(stop, trackIdsToPosition);
        const timetablePolygon = mkTimetablePolygon(stop, referenceDate, drawParams.aimedY, timetableOffset);
        const isActual = drawParams.arrivalIsActual && drawParams.departureIsActual;

        // Draw polygon for dwelling
        const boundingBox: [x: number, y: number][] = [
            [drawParams.entryTime - referenceDate, drawParams.plannedY + dwellPolygonWidth / 2.0],
            [drawParams.entryTime - referenceDate, drawParams.plannedY - dwellPolygonWidth / 2.0],
            [drawParams.exitTime - referenceDate, drawParams.plannedY - dwellPolygonWidth / 2.0],
            [drawParams.exitTime - referenceDate, drawParams.plannedY + dwellPolygonWidth / 2.0],
        ];

        let dwellPolygon: [x: number, y: number][];
        const minimumDrawnDwellingTime = 30 * 1000;
        if (drawParams.departureTime - drawParams.arrivalTime >= minimumDrawnDwellingTime) {
            dwellPolygon = [
                [drawParams.arrivalTime - referenceDate, drawParams.plannedY + dwellPolygonWidth / 2.0],
                [drawParams.arrivalTime - referenceDate, drawParams.plannedY - dwellPolygonWidth / 2.0],
                [drawParams.departureTime - referenceDate, drawParams.plannedY - dwellPolygonWidth / 2.0],
                [drawParams.departureTime - referenceDate, drawParams.plannedY + dwellPolygonWidth / 2.0],
            ];
        } else {
            dwellPolygon = [
                [drawParams.centerDwellTime - minimumDrawnDwellingTime / 2 - referenceDate, drawParams.plannedY + dwellPolygonWidth / 2.0],
                [drawParams.centerDwellTime - minimumDrawnDwellingTime / 2 - referenceDate, drawParams.plannedY - dwellPolygonWidth / 2.0],
                [drawParams.centerDwellTime + minimumDrawnDwellingTime / 2 - referenceDate, drawParams.plannedY - dwellPolygonWidth / 2.0],
                [drawParams.centerDwellTime + minimumDrawnDwellingTime / 2 - referenceDate, drawParams.plannedY + dwellPolygonWidth / 2.0],
            ];
        }



        const fillPolygon = true;
        var path = getPolygon(color, isActual, fillPolygon);
        addPolygonToPath(path, dwellPolygon, true);

        var entryTextPt: [x: number, y: number] | null = null;
        var exitTextPt: [x: number, y: number] | null = null;

        if (drawInfo?.showStationEntryExitTimes) {
            var linePath = getLine(color, isActual);
            const entryLineYOffset = boolFactor(!lineIsAbove(stop.EntryLine)) * entryExitLineOffset;
            const exitLineYOffset = boolFactor(!lineIsAbove(stop.ExitLine)) * entryExitLineOffset;

            const [entryTime, exitTime] = !drawInfo?.drawEntryExitWaitTimes ?
                [drawParams.entryTime, drawParams.exitTime] :
                [drawParams.arrivalTime - train.Stops[0].EntryWaitTime*1000.0, drawParams.departureTime + train.Stops[0].ExitWaitTime*1000.0];

            // Draw lines for entry/exit with actual travel time
            linePath.moveTo(entryTime - referenceDate, drawParams.plannedY + entryLineYOffset);
            linePath.lineTo(drawParams.arrivalTime - referenceDate, drawParams.plannedY + entryLineYOffset);

            linePath.moveTo(drawParams.departureTime - referenceDate, drawParams.plannedY + exitLineYOffset);
            linePath.lineTo(exitTime - referenceDate, drawParams.plannedY + exitLineYOffset);

            entryTextPt = [entryTime, drawParams.plannedY + entryLineYOffset];
            exitTextPt = [exitTime, drawParams.plannedY + exitLineYOffset];
        }

        // const latenessSeconds = stop.ActualPlatformDeparture != null ?
        //         (stop.ActualPlatformDeparture - stop.AimedPlatformDeparture) / 1000.0 :
        //         stop.Lateness.EstimatedLateness;

        // if(latenessSeconds > 2*60) {
        //     const latenessColor = (latenessSeconds > 5*60) ? "Crimson" : "GoldenRod";
        //     const path = getLine(latenessColor);
        //     addPolygonToPath(path, dwellPolygon, true);
        // } 


        drawings.push({
            train,
            color,
            timetablePolygon,
            boundingBox,
            centerTime: drawParams.centerTime,
            centerDwellTime: drawParams.centerDwellTime,
            y: drawParams.plannedY,
            entryTextPt,
            exitTextPt,
            isActual,
            usesWestboundTrack: drawParams.usesWestboundTrack,
        });
    }

    const polygons = Array.from(polygonsStyleMap.entries()).map(([key, obj]) => obj);
    const lines = Array.from(linesStyleMap.entries()).map(([key, obj]) => obj);

    return { drawings, referenceDate, polygons, lines };
}

const minimumSignificantDwellingTime = 2000;

function TrackIdsToPosition(stationRouting: StationRouting) {
    const station = stationRouting.Stations[0];
    // Lookup track id -> track index and use index as coordinate.
    const trackIdsToPosition = {} as any;
    for (var i = 0; i < station.TrackNames.length; i += 1)
        trackIdsToPosition[station.TrackNames[i]] = i;
    return trackIdsToPosition;
}

function* iterTrains(stationRouting: StationRouting, stationId: string) {
    for (const train of stationRouting.Trains) {
        for (const stop of train.Stops) {
            if (stop.StationId == stationId) {
                // Create a path for drawing this train on this stop
                yield { train, stop };
            }
        }
    }
}

const entryExitLowBottom = (x: number) => (x + 0.20);
const entryExitLowTop = (x: number) => (x + 0.0);

const entryExitHighTop = (x: number) => (x - 0.20);
const entryExitHighBottom = (x: number) => (x - 0.0);

const entryExitBottom = (l: string | null, x: number) => lineIsAbove(l) ? entryExitHighBottom(x) : entryExitLowBottom(x);
const entryExitTop = (l: string | null, x: number) => lineIsAbove(l) ? entryExitHighTop(x) : entryExitLowTop(x);

// const aboveTravel = (x: number) => (x - 0.10);
const dwellTop = (x: number) => (x - 0.2);
const dwellBottom = (x: number) => (x + 0.2);

const aboveEntryExitIndicator = (x: number) => (x - 0.3);
const belowEntryExitIndicator = (x: number) => (x + 0.3);

export const lineIsAbove = (l: string | null) => l == 'N' ? true : false;

const entryExitStubTimeOffset = 1 * 60 * 1000;


function mkTrainShapeAlternativeStyle(referenceDate: number, trackIdsToPosition: any, stop: StationRoutingStop, train: StationRoutingTrain) {
    const passingThrough = stop.AimedPlatformDeparture - stop.AimedPlatformArrival < minimumSignificantDwellingTime;
    const { aimedY, entryTime, plannedY, arrivalTime, departureTime, exitTime, centerDwellTime, arrivalIsActual, departureIsActual, centerTime, usesWestboundTrack } = drawParameters(stop, trackIdsToPosition);

    var polygonActual: [number, number][] | null = null;
    var polygonPlanned: [number, number][] | null = null;
    var boundingBox: [number, number][];
    var arrivalPolygon: [number, number][];
    var departurePolygon: [number, number][];

    var timetablePolygon: [number, number][] = mkTimetablePolygon(stop, referenceDate, aimedY, timetableOffset);

    boundingBox = [
        [entryTime - referenceDate, entryExitBottom(stop.EntryLine, plannedY)],
        [arrivalTime - referenceDate, entryExitBottom(stop.EntryLine, plannedY)],
        [arrivalTime - referenceDate, dwellBottom(plannedY)],
        [departureTime - referenceDate, dwellBottom(plannedY)],
        [departureTime - referenceDate, entryExitBottom(stop.ExitLine, plannedY)],
        [exitTime - referenceDate, entryExitBottom(stop.ExitLine, plannedY)],
        [exitTime - referenceDate, entryExitTop(stop.ExitLine, plannedY)],
        [departureTime - referenceDate, entryExitTop(stop.ExitLine, plannedY)],
        [departureTime - referenceDate, dwellTop(plannedY)],
        [arrivalTime - referenceDate, dwellTop(plannedY)],
        [arrivalTime - referenceDate, entryExitTop(stop.EntryLine, plannedY)],
        [entryTime - referenceDate, entryExitTop(stop.EntryLine, plannedY)],
    ];

    arrivalPolygon = [
        [centerDwellTime - referenceDate, dwellTop(plannedY)],
        [arrivalTime - referenceDate, dwellTop(plannedY)],
        [arrivalTime - referenceDate, entryExitTop(stop.EntryLine, plannedY)],
        [entryTime - referenceDate, entryExitTop(stop.EntryLine, plannedY)],
        [entryTime - referenceDate, entryExitBottom(stop.EntryLine, plannedY)],
        [arrivalTime - referenceDate, entryExitBottom(stop.EntryLine, plannedY)],
        [arrivalTime - referenceDate, dwellBottom(plannedY)],
        [centerDwellTime - referenceDate, dwellBottom(plannedY)],
    ];

    departurePolygon = [
        [centerDwellTime - referenceDate, dwellBottom(plannedY)],
        [departureTime - referenceDate, dwellBottom(plannedY)],
        [departureTime - referenceDate, entryExitBottom(stop.ExitLine, plannedY)],
        [exitTime - referenceDate, entryExitBottom(stop.ExitLine, plannedY)],
        [exitTime - referenceDate, entryExitTop(stop.ExitLine, plannedY)],
        [departureTime - referenceDate, entryExitTop(stop.ExitLine, plannedY)],
        [departureTime - referenceDate, dwellTop(plannedY)],
        [centerDwellTime - referenceDate, dwellTop(plannedY)],
    ];

    const polygonOffset = lineIsAbove(stop.EntryLine) ? 0.05 : -0.05;
    boundingBox = boundingBox.map(([x, y]) => [x, y + polygonOffset]);
    arrivalPolygon = arrivalPolygon.map(([x, y]) => [x, y + polygonOffset]);
    departurePolygon = departurePolygon.map(([x, y]) => [x, y + polygonOffset]);
    timetablePolygon = timetablePolygon.map(([x, y]) => [x, y + polygonOffset]);

    if (arrivalIsActual && departureIsActual) {
        polygonActual = boundingBox;
    } else if (!arrivalIsActual && !departureIsActual) {
        polygonPlanned = boundingBox;
    } else {
        polygonActual = arrivalIsActual ? arrivalPolygon : departurePolygon;
        polygonPlanned = arrivalIsActual ? departurePolygon : arrivalPolygon;
    }

    const { entry, exit }: { entry: [x: number, y: number][]; exit: [x: number, y: number][]; } = entryExitDiagonalLines(stop, plannedY, entryTime, polygonOffset, exitTime);

    return {
        train, timetablePolygon, boundingBox, polygonPlanned, polygonActual, entry, exit, centerTime, y: plannedY, isActual: arrivalIsActual && departureIsActual,
        usesWestboundTrack,
        centerDwellTime: centerDwellTime,
        entryTextPt: entry[0], exitTextPt: exit[0]
    };
}

const westBoundTrackIds = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

type DrawParameters = {
    aimedY: any;
    entryTime: number;
    plannedY: any;
    arrivalTime: number;
    departureTime: number;
    exitTime: number;
    centerDwellTime: number;
    arrivalIsActual: boolean;
    departureIsActual: boolean;
    centerTime: number;
    usesWestboundTrack: boolean,
};

function mkTimetablePolygon(stop: StationRoutingStop, referenceDate: number, aimedY: any, timetableOffset: number): [number, number][] {
    return [
        [stop.AimedStationEntry - referenceDate, entryExitBottom(stop.EntryLine, aimedY) + timetableOffset],
        [stop.AimedPlatformArrival - referenceDate, entryExitBottom(stop.EntryLine, aimedY) + timetableOffset],
        [stop.AimedPlatformArrival - referenceDate, dwellBottom(aimedY) + timetableOffset],
        [stop.AimedPlatformDeparture - referenceDate, dwellBottom(aimedY) + timetableOffset],
        [stop.AimedPlatformDeparture - referenceDate, entryExitBottom(stop.ExitLine, aimedY) + timetableOffset],
        [stop.AimedStationExit - referenceDate, entryExitBottom(stop.ExitLine, aimedY) + timetableOffset],
        [stop.AimedStationExit - referenceDate, entryExitTop(stop.ExitLine, aimedY) - timetableOffset],
        [stop.AimedPlatformDeparture - referenceDate, entryExitTop(stop.ExitLine, aimedY) - timetableOffset],
        [stop.AimedPlatformDeparture - referenceDate, dwellTop(aimedY) - timetableOffset],
        [stop.AimedPlatformArrival - referenceDate, dwellTop(aimedY) - timetableOffset],
        [stop.AimedPlatformArrival - referenceDate, entryExitTop(stop.EntryLine, aimedY) - timetableOffset],
        [stop.AimedStationEntry - referenceDate, entryExitTop(stop.EntryLine, aimedY) - timetableOffset],
    ];
}

function drawParameters(stop: StationRoutingStop, trackIdsToPosition: any): DrawParameters {
    const arrivalIsActual = stop.ActualPlatformArrival != null;
    const entryTime = stop.ActualStationEntry != null ? stop.ActualStationEntry : stop.PlannedStationEntry;
    const arrivalTime = stop.ActualPlatformArrival != null ? stop.ActualPlatformArrival : stop.PlannedPlatformArrival;

    const departureIsActual = stop.ActualPlatformDeparture != null;
    const departureTime = stop.ActualPlatformDeparture != null ? stop.ActualPlatformDeparture : stop.PlannedPlatformDeparture;
    const exitTime = stop.ActualStationExit != null ? stop.ActualStationExit : stop.PlannedStationExit;

    const centerTime = (entryTime + exitTime) / 2.0;
    const centerDwellTime = (arrivalTime + departureTime) / 2.0;


    const plannedY = trackIdsToPosition[stop.PlannedTrackId];
    const aimedY = trackIdsToPosition[stop.AimedTrackId ?? stop.PlannedTrackId];

    // Oslo S westbound tracks.
    const usesWestboundTrack = ["2","3","4","5","6","7","8"].includes(stop.PlannedTrackId);

    return { aimedY, entryTime, plannedY, arrivalTime, departureTime, exitTime, centerDwellTime, arrivalIsActual, departureIsActual, centerTime, usesWestboundTrack };
}

function entryExitDiagonalLines(stop: StationRoutingStop, plannedY: any, entryTime: number, polygonOffset: number, exitTime: number) {
    const entryY = lineIsAbove(stop.EntryLine) ?
        [aboveEntryExitIndicator(plannedY), entryExitHighTop(plannedY)] : [belowEntryExitIndicator(plannedY), entryExitLowBottom(plannedY)];
    const exitY = lineIsAbove(stop.ExitLine) ?
        [aboveEntryExitIndicator(plannedY), entryExitHighTop(plannedY)] : [belowEntryExitIndicator(plannedY), entryExitLowBottom(plannedY)];
    const entry: [x: number, y: number][] = [
        [entryTime - entryExitStubTimeOffset, entryY[0] + polygonOffset],
        [entryTime, entryY[1] + polygonOffset]
    ];
    const exit: [x: number, y: number][] = [
        [exitTime + entryExitStubTimeOffset, exitY[0] + polygonOffset],
        [exitTime, exitY[1] + polygonOffset]
    ];
    return { entry, exit };
}

function boolFactor(b: boolean) {
    return b ? 1 : -1;
}

