import * as d3 from 'd3';
import * as FlatbushClass from 'flatbush';
import { TrainDrawing } from './renderdata';


export type Rectangle = {
    minX: number,
    minY: number,
    maxX: number,
    maxY: number,
};

function axisAlignedBoundingBox(polygon: [x: number, y: number][]): Rectangle {
    return {
        minX: d3.min(polygon, (p) => p[0]) ?? 0,
        maxX: d3.max(polygon, (p) => p[0]) ?? 0,
        minY: d3.min(polygon, (p) => p[1]) ?? 0,
        maxY: d3.max(polygon, (p) => p[1]) ?? 0,
    }
}

export type GeomLookup = (pt: [x: number, y: number]) => TrainDrawing | null;

export function mkGeomIndex(trains: TrainDrawing[]): GeomLookup {
    const index = new FlatbushClass(trains.length);
    for (const train of trains) {
        const aabb = axisAlignedBoundingBox(train.boundingBox);
        index.add(aabb.minX, aabb.minY, aabb.maxX, aabb.maxY);
    }
    index.finish();

    return ([x, y]) => {
        const closest = index.neighbors(x, y, 1).map(idx => trains[idx]);
        if (closest.length == 0) return null;
        const train = closest[0];
        const [dx, dy] = distanceXY(train, [x, y]);
        if (dx > 30000 || dy > 0.5) return null;
        return train;
    };
}

function distanceXY(train: TrainDrawing, [x, y]: [number, number]): [number, number] {
    const aabb = axisAlignedBoundingBox(train.boundingBox);
    return [distanceToInterval(x, aabb.minX, aabb.maxX),
    distanceToInterval(y, aabb.minY, aabb.maxY)]
}

function distanceToInterval(x: number, a: number, b: number): number {
    if (x >= a && x <= b) return 0.0;
    return Math.min(Math.abs(x - a), Math.abs(x - b));
}