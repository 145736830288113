import { StationRouting } from "@/models/stationroutingdata";
import * as d3 from "d3";

export type Margins = {
    left: number,
    right: number,
    top: number,
    bottom: number,
};

const defaultZoomInHours = 2;
export const STATION_GRAPH_MARGINS: Margins = { top: 50, right: 0, bottom: 25, left: 175 };
export const fontSizes = {
    "largeScreen": { "small": "11px", "medium": "13px", "large": "15px" },
    "smallScreen": { "small": "10px", "medium": "11px", "large": "13px" },
};

/* Compute the initial D3 scales for the StationRouting part of the train graph.
 * The time (X) scale puts the Now time at 1/4 from the left, zoomed to span 4 hours.
 * The track (Y) scale spans over all the tracks of the station.
 * */
export function stationGraphBaseScales(now: number, stationRouting: StationRouting, size: DOMRect, margins: Margins) {
    if (stationRouting.Stations.length == 0)
        return null;

    const tracksExtent = [stationRouting.Stations[0].TrackNames.length, 0 - 1.0];
    const t0 = now - 0.25 * 3600 * 1000 * defaultZoomInHours;
    const t1 = now + 0.75 * 3600 * 1000 * defaultZoomInHours;
    const x0 = d3
        .scaleTime()
        .domain([t0, t1])
        // .range([margins.left, size.width - margins.right]);
        .range([-5, size.width +5 ]);
    const y0 = d3
        .scaleLinear()
        .domain(tracksExtent)
        .range([size.height - margins.bottom, margins.top]);
    return { x0, y0 };
};