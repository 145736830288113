import { translate } from "@/translation";
import { useLocalStorage } from "@/utils/utils";
import { StateUpdater, useState } from "preact/hooks";
import { Log, useLog } from "./log";

export type AppState = {
    log: Log,
    userSettings: any,
    documentSource: DocumentSource,
    setDocumentSource: StateUpdater<DocumentSource>,
    logout: () => void,
    gettext: (s: string) => string,
};

export type DocumentSource = {
    type: string,
};

// Track state for:
//  - document source (i.e. login status in dispatching application)
//  - application log
//  - user settings, including language/translation
export function useAppState(): AppState {
    const [documentSource, setDocumentSource] = useState({ type: "none" });
    const log = useLog();
    const [language, setLanguage] = useLocalStorage("goto_lang", "no");
    const [advanced, setAdvanced] = useLocalStorage("goto_poweruser", false);
    const [drawParams, setDrawParams] = useLocalStorage("goto_drawParams", {
        trainNumbers: true,
        minutes: false,
        seconds: false,
        tracks: false,
        showTimetable: true,
        showPlanned: true,
        showActual: true,
    });
    const userSettings = { language, setLanguage, advanced, setAdvanced, drawParams, setDrawParams };

    const logout = () => {
        window.sessionStorage.removeItem("loggedin");
        setDocumentSource({ type: "none" });
    };
    
    const gettext: (t: string) => string = (t) => translate(language, t);
    return {
        log,
        userSettings,
        gettext,
        documentSource,
        setDocumentSource,
        logout,
    };
}