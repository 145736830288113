import { toast } from '../toasts';
import { fetchJson, sendObject, sendReceiveObject } from '@/service/fetch';
import { AppState } from '@/models/appstate';
import { LogLevel } from '@/models/log';
import { parseDate, parseMaybeDate, parseStationRouting } from './parse';
import { StateUpdater } from 'preact/hooks';
import { HasStationRouting, isEnteringLine, isExitingLine } from '@/models/traingraphdata';
import { defaultStationRoutingData } from '@/models/stationroutingdata';

// Poll: network connection to server for continuously receiving updates to the
// train graph data, and for updating the overrides.

// The API interface for editing overrides on the server. 



export const serverOverrideSetters = (appState: AppState) =>
({
    newOverride: (o: any) => {
        sendObject(appState, "/Overrides/AddOverrideAPI", o, null);
        // TODO add temporarily to local traingraph overrides
    },
    deleteOverride: (o: any) => {
        sendObject(appState, "/Overrides/DeleteOverrideAPI", o, null);
        // TODO temporarily remove local traingraph override
    }
});

/// Feedback object should consist of strings in `Source`, `Sender`, `Topic`, `Contents`.
export const sendFeedback = (appState: AppState, feedback: any) => sendReceiveObject(appState, "/Feedback/SubmitAPI", {
    Sender: feedback.Sender,
    Contents: feedback.Contents,
    Screenshot: feedback.screenshot.toDataURL(),
});

// Preprocessing of the server train graph data and overrides for use in the GUI.
export function prepareData(trainGraphData: any, overrides: any, subNetworkName: string | null): HasStationRouting | null {
    if (!trainGraphData)
        return { StationRouting: defaultStationRoutingData };

    const subnetworkIndices = new Map<number, number>();
    const subnetwork = trainGraphData.SubNetworks.find((sn: any) => sn.Name === subNetworkName) ?? trainGraphData.SubNetworks[0];
    const subnetworkStations = subnetwork.Stations;

    for (let idx = 0; idx < subnetworkStations.length; idx += 1) {
        subnetworkIndices.set(subnetworkStations[idx].Index, idx);
    }

    trainGraphData.SubNetwork = subnetworkStations;
    trainGraphData.SubNetworkIndices = subnetworkIndices;

    const stationIndexById = trainGraphData.Stations.reduce((obj: any, st: any, idx: any) => ({ ...obj, [st.Id]: idx }), {});
    for (const train of trainGraphData.Trains) {
        for (const stop of train.Stops) {
            stop.PlannedArrival_date = parseMaybeDate(stop.PlannedArrival);
            stop.PlannedDeparture_date = parseMaybeDate(stop.PlannedDeparture);

            stop.AimedArrival_date = parseMaybeDate(stop.AimedArrival);
            stop.AimedDeparture_date = parseMaybeDate(stop.AimedDeparture);
            stop.ActualArrival_date = parseMaybeDate(stop.ActualArrival);
            stop.ActualDeparture_date = parseMaybeDate(stop.ActualDeparture);

            const stationId = stop.StationId || stop.Station.Id;
            stop.StationIdx = stationIndexById[stationId];
            stop.Station = trainGraphData.Stations[stop.StationIdx];
            stop.YCoord = subnetworkIndices.get(stop.StationIdx);
        }

        train.SubNetworkStops = train.Stops.filter((s: any, i :number) => {
            const hasCoords =  s.YCoord !== undefined;
            const isEnteringAndExiting = isEnteringLine(train, i) && isExitingLine(train, i);
            return hasCoords && !isEnteringAndExiting;
        });
    }

    const stationRouting = trainGraphData.StationRouting ? parseStationRouting(trainGraphData.StationRouting) : null;

    if (trainGraphData.StationRouting && trainGraphData.StationRouting.Trains) {
        for (const train of trainGraphData.StationRouting.Trains) {
            for (const stop of train.Stops) {
                stop.AimedStationEntry_date = parseMaybeDate(stop.AimedStationEntry);
                stop.AimedPlatformArrival_date = parseMaybeDate(stop.AimedPlatformArrival);
                stop.AimedPlatformDeparture_date = parseMaybeDate(stop.AimedPlatformDeparture);
                stop.AimedStationExit_date = parseMaybeDate(stop.AimedStationExit);

                stop.PlannedStationEntry_date = parseMaybeDate(stop.PlannedStationEntry);
                stop.PlannedPlatformArrival_date = parseMaybeDate(stop.PlannedPlatformArrival);
                stop.PlannedPlatformDeparture_date = parseMaybeDate(stop.PlannedPlatformDeparture);
                stop.PlannedStationExit_date = parseMaybeDate(stop.PlannedStationExit);
            }
        }
    }

    const doubleTracks = {} as any;
    for (const track of trainGraphData.Tracks) {
        if (track.IsDoubleTrack) {
            doubleTracks[track.FromStationId] = doubleTracks[track.FromStationId] || {};
            doubleTracks[track.ToStationId] = doubleTracks[track.ToStationId] || {};

            doubleTracks[track.FromStationId][track.ToStationId] = true;
            doubleTracks[track.ToStationId][track.FromStationId] = true;
        }
    }
    trainGraphData.ConnectionsWithDoubleTrack = doubleTracks;

    if (typeof trainGraphData.Now !== 'number') {
        trainGraphData.Now = parseDate(trainGraphData.Now);
    }
    trainGraphData.AllOverrides = overrides;


    trainGraphData.TrackClosures = [];
    trainGraphData.TrainLeavesNotBefore = [];
    trainGraphData.FixedMeetings = [];
    trainGraphData.TrainLengthOverrides = [];
    trainGraphData.Cancellations = { full: [], before: [], after: [] };
    trainGraphData.TrainNotBefore = { Arrives: [], Departs: [] };
    trainGraphData.InternalTracksClosures = [];
    if (overrides) {
        for (const ov of overrides) {
            if (ov.Type == "TrackClosure") {
                trainGraphData.TrackClosures.push({
                    x1: trainGraphData.Stations.find((s: any) => s.Id == ov.StationId).Index,
                    x2: trainGraphData.Stations.find((s: any) => s.Id == ov.OtherStationId).Index,
                    t1: ov.Time,
                    t2: ov.Time + ov.Duration,
                });
            } else if (ov.Type == "TrainsMeet") {
                ov.StationIdx = stationIndexById[ov.StationId];
                const stop1 = trainGraphData.Trains.find((t: any) => t.Id == ov.TrainId).Stops.find((s: any) => s.Station.Id == ov.StationId);
                const stop2 = trainGraphData.Trains.find((t: any) => t.Id == ov.OtherTrainId).Stops.find((s: any) => s.Station.Id == ov.StationId);
                const t1 = stop1.ActualDeparture_date || stop1.PlannedDeparture_date;
                const t2 = stop2.ActualDeparture_date || stop2.PlannedDeparture_date;
                ov.TimeOfMeeting = 0.5 * (t1 + t2);
                trainGraphData.FixedMeetings.push(ov);
            } else if (ov.Type == "CancelTrain") {
                trainGraphData.Cancellations.full.push(ov);
            } else if (ov.Type == "CancelTrainBefore") {
                trainGraphData.Cancellations.before.push(ov);
            } else if (ov.Type == "CancelTrainAfter") {
                trainGraphData.Cancellations.after.push(ov);
            } else if (ov.Type == "TrainLeavesNotBefore") {
                trainGraphData.TrainNotBefore.Departs.push(ov);
            } else if (ov.Type == "TrainArrivesNotBefore") {
                trainGraphData.TrainNotBefore.Arrives.push(ov);
            } else if (ov.Type == "TrainLength") {
                trainGraphData.TrainLengthOverrides.push(ov);
            } else if (ov.Type == "InternalTrackClosure") {
                trainGraphData.InternalTracksClosures.push(ov);
            } else {
                console.log("Unsupported override type.");
                console.log(ov);
            }
        }
    }

    return { ...trainGraphData, StationRouting: stationRouting };
}

// Ininite async loop for server updates. Cancelled by the returned function.
export function pollTrainGraphData(appState: AppState, setServerData: StateUpdater<any>, setSolverStatus: StateUpdater<any>, setConnectionStatus: StateUpdater<any>) {
    const gettext = appState.gettext;

    var cancelled = false;
    var lastServerStatus: any = null;
    const updateThread = async function () {
        while (true) {
            if (cancelled) return;
            try {
                const serverData = await fetchJson(gettext, "../TrainGraph/TrainGraphData");
                (window as any)._data = serverData;
                if (cancelled) return;
                setConnectionStatus({ status: "ok", time: Date.now() });
                try {
                    if (serverData.NotAuthorized) {
                        appState.logout();
                    } else {
                        // Make the server's messages object into an array to be deterministically serializable for comparison.
                        const status = serverData.StatusMessages;

                        // translate status messages
                        var i;
                        for (i = 0; i < (status.Errors || []).length; i++) status.Errors[i] = gettext(status.Errors[i]);
                        for (i = 0; i < (status.Info || []).length; i++) status.Info[i] = gettext(status.Info[i]);
                        for (i = 0; i < (status.Warnings || []).length; i++) status.Warnings[i] = gettext(status.Warnings[i]);

                        const openLog = (remove: any) => { appState.log.setLogOpen(true); remove(); };
                        for (var error of (status.Errors || [])) {
                            if (!lastServerStatus || !(lastServerStatus.Errors || []).includes(error)) {
                                toast(LogLevel.Error, error, openLog, 10000);
                            }
                        }
                        for (var info of (status.Info || [])) {
                            if (!lastServerStatus || !(lastServerStatus.Info || []).includes(info)) {
                                toast(LogLevel.Info, info, openLog);
                            }
                        }
                        for (var warning of (status.Warnings || [])) {
                            if (!lastServerStatus || !(lastServerStatus.Warnings || []).includes(warning)) {
                                toast(LogLevel.Warn, warning, openLog);
                            }
                        }
                        lastServerStatus = status;

                        setSolverStatus(status);

                        if (serverData.TrainGraphData) {
                            setServerData(serverData);
                        }
                    }
                } catch (e) {
                    setConnectionStatus({ status: "serverError", time: Date.now() });
                    const message = (e instanceof Error) ? e.message : "unknown error";
                    appState.log.error(`${gettext("Error in train graph data")}: ${message}`, "prepareData");
                }
            } catch (e) {
                setConnectionStatus({ status: "serverError", time: Date.now() });
                const message = (e instanceof Error) ? e.message : "unknown error";
                appState.log.error(`${gettext("Error fetching train graph data")}: ${message}`, "pollTrainGraph");
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    };
    updateThread();
    return () => { cancelled = true; };
}


