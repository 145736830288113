import { Lateness } from "@/models/stationroutingdata";
import { displayTimeMinutes, displayTimeSeconds } from "@/utils/utils";
import { ComponentChildren, FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import { DrawInfo } from "./draw";
import { TrainDrawing } from "./renderdata";
import { stationName } from "./stationnames";

export function TrainInfoContents({ trainDwg }: { trainDwg: TrainDrawing | null }) {
    if (trainDwg == null)
        return null;
    return <>
        <b>Tog {trainDwg.train.Number}</b><br />{trainDwg.train.ServiceName || ""} {trainDwg.train.KindTypeAndOperator || ""}<br />
        {TrainTimeInfo(trainDwg)}
        <br />
        <p>{TrainRollingStockInfo(trainDwg)}</p>
        <p>{TrainRouteInfo(trainDwg)}</p>
        <p>{TrainLatenessInfo(trainDwg)}</p>
    </>;
}

const formatLatenessSeconds = (x: number) => `${Math.round(x / 60.0)} min`;

function describeLateness(lateness: Lateness): string[] {
    if (lateness.OriginalLateness === 0)
        return ["I rute."];

    const output = [];

    if (lateness.OriginalLatenessStation == null) {
        output.push(`Forsinkelse ${formatLatenessSeconds(lateness.OriginalLateness)} ved ukjent stasjon.`);
    } else {
        const type = lateness.OriginalLatenessStationDeparture ? "avgang fra" : "ankomst til";
        output.push(`Forsinkelse ${formatLatenessSeconds(lateness.OriginalLateness)} ved ${type} ${stationName(lateness.OriginalLatenessStation)}.`)
    }

    if (lateness.EstimatedLateness === 0) {
        output.push(`Antatt i rute ved ankomst til ${stationName("NO.OSL")}.`);
    } else {
        output.push(`Antatt forsinkelse ${formatLatenessSeconds(lateness.EstimatedLateness)} ved ankomst til ${stationName("NO.OSL")}.`)
    }

    return output;
}

function TrainLatenessInfo(trainDwg: TrainDrawing) {
    var stop = trainDwg.train.Stops[0];
    return <><b>Forsinkelse</b><br />{describeLateness(stop.Lateness).map(s => (<>{s}<br /></>))}</>;
}

function TrainRollingStockInfo(trainDwg: TrainDrawing) {
    return (trainDwg.train.RollingStocks || []).map(r => {
        return <div class="vognopptak">
            <b>Vognopptak</b> {stationName(r.FromStationId || "ukjent")} - {stationName(r.ToStationId || "ukjent")}
            <ul class="vognopptak">
                <li class="vognopptak"> Lengde: {r.Length} </li>
                <li class="vognopptak"> Vekt: {r.Weight} </li>
                <li class="vognopptak"> Lok.: {(r.TractionUnits || []).join(", ") || "ukjent"} ({(r.WagonUnits || []).length || "ukjent ant."} vogner) </li>
            </ul>
        </div>;
    });
}

const formatRoute = (r: string) => r == "SinkRoute" ? "på linjen" : r;

function TrainRouteInfo(trainDwg: TrainDrawing) {
    const stop = trainDwg.train.Stops[0];
    const t0 = stop.PlannedStationEntry;
    const format = (d: number) => displayTimeSeconds(new Date(d));
    return <><b>Togveier</b> (beregnet)<br /><table class="tooltiptable">
        {stop.Routes.map(r =>
            <tr>
                <td class="tooltipcell">{formatRoute(r.RouteName)}</td>
                <td class="tooltipcell">{format(r.PlannedTime)}</td>
            </tr>)}
    </table></>;
}

const formatTrackName = (track: string | null) => `Spor ${track || "ukjent"}`;

function TrainTimeInfo(trainDwg: TrainDrawing) {
    const format = (d: number) => displayTimeMinutes(new Date(d));
    const stop = trainDwg.train.Stops[0];

    const table = [];
    const lines: [string, number | null, number | null, string | null][] = [
        ["Ruteplan", stop.AimedPlatformArrival, stop.AimedPlatformDeparture, stop.AimedTrackId],
        ["Faktisk", stop.ActualPlatformArrival, stop.ActualPlatformDeparture, stop.ActualTrackId],
        ["Beregnet", stop.PlannedPlatformArrival, stop.PlannedPlatformDeparture, stop.PlannedTrackId]
    ];

    for (const line of lines) {
        const [name, arrival, departure, track] = line;
        var arr = arrival && format(arrival);
        var dep = departure && format(departure);
        if (arr || dep) {
            table.push([name, arr, "/", dep, formatTrackName(track)]);
        }
    }

    return <table class="tooltiptable">
        {table.map(cells =>
            <tr>
                {cells.map(cell => <td class="tooltipcell">{cell}</td>)}
            </tr>)}
    </table>;
}


export const OverlaySidebar: FunctionalComponent = ({ children }) => {
    const [content, setContent] = useState<ComponentChildren>(null);
    const [visible, setVisible] = useState(false);
    const hasChildren = children && (!Array.isArray(children) || children.length == 0);
    if (hasChildren) {
        setContent(children);
    }

    useEffect(() => {
        if (hasChildren) {
            setVisible(true);
        } else {
            const cancel = setTimeout(() => { setVisible(false); }, 500);
            return () => { clearTimeout(cancel); };
        }
    }, [hasChildren]);


    const style = ["overlaysidebar", ...(visible ? ["overlaysidebarvisible"] : [])];
    return <div class={style.join(" ")}>
        {content}
    </div>;
}

export enum TooltipSide { Left, Right };

export type TooltipProps = {
    drawInfo: DrawInfo,
    pt: [number, number],
    side: TooltipSide,
    clickable: boolean,
};

export const ToolTip: FunctionalComponent<TooltipProps> = ({ drawInfo, pt, side, clickable, children }) => {
    var xLocation;
    if (side == TooltipSide.Left) {
        const x = (drawInfo.scales.size.width - (pt[0] - 20));
        xLocation = `right: ${x}px;`;
    } else if (side == TooltipSide.Right) {
        const x = pt[0] + 20;
        xLocation = `left: ${x}px;`;
    }

    var yLocation;
    if (pt[1] > drawInfo.scales.size.height / 2) {
        yLocation = `bottom: ${drawInfo.scales.size.height - (pt[1] + 50)}px;`;
    } else {
        yLocation = `top: ${(pt[1] - 50)}px;`;
    }

    const pointerEvents = clickable ? "all" : "none";

    return (
        <div class="visittooltip"
            style={`position: absolute; ${xLocation} ${yLocation} padding: 1rem; border: 2px solid gray; background: white; pointer-events: ${pointerEvents};`}>
            {children}
        </div>
    );
}