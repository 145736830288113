import { lerp2 } from '@/utils/utils';

export function* trainVisits(trainGraph: any) {
    for (const train of trainGraph.Trains) {
        if (train.Stops.length <= 1) continue;
        for (const stop of train.Stops) {
            if (stop.YCoord === undefined) continue;
            var t1 = stop.PlannedArrival_date || stop.ActualArrival_date;
            var t2 = stop.PlannedDeparture_date || stop.ActualDeparture_date;
            if (!t1 && !t2) continue; // Too little information to mark a visit here.
            t1 = t1 || t2;
            t2 = t2 || t1;
            if (stop.IsCancelled) continue;
            var pt = [t1 + 0.5 * (t2 - t1), stop.YCoord];
            yield { type: "visit", train, stop, pt };
        }
    }
}

export const lineTypes = [
    {
        name: "Timetable", color: (t: any) => {
            if (t && t.KindTypeAndOperator && t.KindTypeAndOperator.substring(0, 2) === "At" /* Service train (SIRI definition) */) {
                return "blue";
            } else { return "black"; }
        },
        dashed: false,
        arrival: (stop: any) => stop.AimedArrival_date,
        departure: (stop: any) => stop.AimedDeparture_date,
        labelStartIdx: 0
    },
    {
        name: "Actual",
        color: (t: any) => "red",
        dashed: false,
        arrival: (stop: any) => stop.ActualArrival_date,
        departure: (stop: any) => stop.ActualDeparture_date,
        labelStartIdx: 1
    },
    {
        name: "Planned",
        color: (t: any) => "red",
        dashed: true,
        arrival: (stop: any) => stop.PlannedArrival_date,
        departure: (stop: any) => stop.PlannedDeparture_date,
        labelStartIdx: 1
    },
];

export function* mkTrainLabels(trainGraph: any, showTimetable: boolean) {
    for (const train of (trainGraph.Trains || [])) {
        for (const lineType of lineTypes) {
            if (!showTimetable && lineType.name === "Timetable") continue;
            for (var i = lineType.labelStartIdx; i < train.SubNetworkStops.length - 1; i += 7) {
                const a = train.SubNetworkStops[i];
                const b = train.SubNetworkStops[i + 1];

                // If the stops are not adjacent, don't draw here.
                if (Math.abs(a.YCoord - b.YCoord) != 1) {
                    continue;
                }

                const departureA = lineType.departure(a);
                const arrivalB = lineType.arrival(b);
                if (lineType.name != "Timetable" && a.IsCancelled) continue;
                if (departureA && arrivalB) {
                    const [ptA, ptB] = [[departureA, a.YCoord],
                    [arrivalB, b.YCoord]];
                    const result = { type: "trainLabel", pt: lerp2(ptA, ptB, 0.25), tangent: [ptB[0] - ptA[0], ptB[1] - ptA[1]], train, stop: a, lineType, secondaryPointFor: null };
                    yield result;
                    yield { type: "trainLabel", pt: lerp2(ptA, ptB, 0.75), tangent: [ptB[0] - ptA[0], ptB[1] - ptA[1]], train, stop: a, lineType, secondaryPointFor: result };
                }
            }
        }
    }
}
