import { html } from 'htm/preact';
import { render } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import { DocumentView } from './documentview';
import { LoginScreen } from './login';
import { useLocalStorage } from './utils/utils';
import { pollTrainGraphData, serverOverrideSetters } from './service/backend';
import { translate } from './translation';
import { toast } from './toasts';
import { fetchJson } from './service/fetch';
import { AppState, useAppState } from './models/appstate';
import { HasStationRouting } from './models/traingraphdata';
import { defaultStationRoutingData } from './models/stationroutingdata';

interface OnlineTrainGraphProperties {
    appState: AppState,
}


// Start an async update thread for polling the server, and find the interface
// for editing overrides on the server.  Forward these to the DocumentView.
function OnlineTrainGraph({ appState }: OnlineTrainGraphProperties) {
    const [serverData, setServerData] = useState({} as any);
    const [solverStatus, setSolverStatus] = useState({} as any);
    const [connectionStatus, setConnectionStatus] = useState({ status: "firstFetch", time: Date.now() });

    useEffect(() => pollTrainGraphData(appState, setServerData, setSolverStatus, setConnectionStatus),
        [setServerData]);

    return html`<${DocumentView} appState=${{ ...appState, connectionStatus }}
		            serverData=${serverData} status=${solverStatus}
                    overrideSetters=${serverOverrideSetters}/>`;
}

// The main application entry point. 
function App() {
    const appState = useAppState();
    (window as any)._app = appState;

    if (appState.documentSource.type == "none") {
        return html`<${LoginScreen} setDocumentSource=${appState.setDocumentSource} gettext=${appState.gettext}/>`;
    } else if (appState.documentSource.type == "online") {
        return html`<${OnlineTrainGraph} appState=${appState} />`;
    } else {
        return html`${appState.gettext("Unknown document type")} ${appState.documentSource.type}.`;
    }
}

// Start the GUI
render(html`<${App} />`, document.body);

(window as any)._toast = toast;

// Debug function to list connected clients. (Clients using from the web interface only).
(window as any)._clients = async () => {
    const connectedClients = await fetchJson((x: any) => x, "../TrainGraph/ConnectedClients");
    if (!connectedClients || connectedClients.length == 0) {
        console.log("No clients connected.");
        return;
    }
    var output = "";
    connectedClients.sort((a: any, b: any) => a.Item2 < b.Item2);
    for (const client of connectedClients) {
        output += " * " + client.Item1 + ": last seen " + new Date(client.Item2) + "\n";
    }
    return output;
};
