﻿import { displayTimeMinutes, lerp2, isIndex } from '@/utils/utils';
import { lineTypes } from './labels';
import { isEnteringLine, isExitingLine } from '@/models/traingraphdata';

const kmGutterWidth = 50;

export const hourLineTimes = (x) => {
    const domain = x.domain();
    const precision = 1000 * 3600;
    const firstHour = new Date(Math.floor(domain[0].getTime() / precision) * precision);
    const lastHour = new Date(Math.ceil(domain[1].getTime() / precision) * precision);
    const hourLines = [];
    for (var d = firstHour; d <= lastHour; d.setHours(d.getHours() + 1)) {
        hourLines.push(d.getTime());
    }
    return hourLines;
};

export function drawTrainGraph({ ctx, trainGraph, scales, margins, fontSizes, drawParams, hoveredStation, hoveredIndex, canHoverVisits, visits, selectedVisits }) {
    ctx.clearRect(0, 0, scales.size.width, scales.size.height);
    ctx.font = `${fontSizes["large"]} sans-serif`;
    if (!trainGraph || !trainGraph.Stations || !scales.x || !scales.y) return;

    if (drawParams.drawFrame) {
        ctx.save();
        ctx.lineWidth = 8;
        ctx.strokeStyle = "rgba(0,0,0,0.25)";
        ctx.beginPath();
        ctx.rect(0, 0, scales.size.width, scales.size.height);
        ctx.stroke();
        ctx.restore();
    }

    // Gutters

    ctx.save();
    ctx.lineWidth = 1;
    ctx.strokeStyle = "rgba(0,0,0,0.25)";
    ctx.beginPath();
    d3.line().context(ctx)([[margins.left + 0.5, margins.top + 0.5],
    [margins.left + 0.5, scales.size.height - margins.bottom + 0.5]]);
    ctx.stroke();


    ctx.lineWidth = 2;
    ctx.strokeStyle = "rgba(0,0,0,0.25)";
    ctx.beginPath();
    d3.line().context(ctx)([[kmGutterWidth + 0.5, margins.top + 0.5],
    [kmGutterWidth + 0.5, scales.size.height - margins.bottom + 0.5]]);
    ctx.stroke();
    ctx.beginPath();
    d3.line().context(ctx)([[scales.size.width - margins.right + 0.5, margins.top + 0.5],
    [scales.size.width - margins.right + 0.5, scales.size.height - margins.bottom + 0.5]]);
    ctx.stroke();
    ctx.restore();

    if (drawParams.drawCanvasHours) {
        ctx.save();
        ctx.lineWidth = 2;
        ctx.strokeStyle = "rgba(0,0,0,1)";
        ctx.beginPath();
        d3.line().context(ctx)([[margins.left + 0.5, scales.y.range()[1] + 0.5], [scales.size.width - margins.right + 0.5, scales.y.range()[1] + 0.5]]);
        ctx.stroke();
        ctx.restore();

        for (const d of hourLineTimes(scales.x)) {
            const hour = `${new Date(d).getHours()}`.padStart(2, '0');

            const xPos = scales.x(d);
            if (xPos + 1.0 < margins.left || xPos - 1.0 > scales.size.width - margins.right) {
                continue;
            }
            ctx.beginPath();
            ctx.strokeStyle = "rgba(0,0,0,1)";
            d3.line().context(ctx)([[scales.x(d) + 0.5, scales.y.range()[1]], [scales.x(d) + 0.5, scales.y.range()[1] - 10.0]]);
            ctx.stroke();
            ctx.textBaseline = "bottom";
            ctx.textAlign = "center";

            ctx.fillText(hour, scales.x(d), scales.y.range()[1] - 15);
        }
    }

    // Station lines
    ctx.save();

    ctx.rect(0, margins.top, scales.size.width, scales.size.height - margins.top - margins.bottom);
    ctx.clip();
    ctx.strokeStyle = "rgba(0,0,0,0.25)";
    trainGraph.SubNetwork.map((subnetworkStation, i) => {
        const s = trainGraph.Stations[subnetworkStation.Index];
        if (s.Type == "Station") {
            ctx.beginPath();
            d3.line().context(ctx)([[margins.left + 0.5, scales.y(i) + 0.5], [scales.size.width - margins.right + 0.5, scales.y(i) + 0.5]]);
            ctx.stroke();
            //ctx.beginPath();
            //d3.line().context(ctx)([[5 + 0.5, this.y(i) + 0.5], [kmGutterWidth + 0.5, this.y(i) + 0.5]]);
            //ctx.stroke();
        }

        var stationNameX = kmGutterWidth + 5;
        if (s.Type == "Blockpoint") {
            ctx.font = `${fontSizes["small"]} sans-serif`;
            stationNameX += 20;
        } else if (s.Type == "Holdeplass") {
            ctx.font = `${fontSizes["medium"]} sans-serif`;
            stationNameX += 20;
        } else if (s.Type == "Station") {
            ctx.font = `${fontSizes["large"]} sans-serif`;
        }

        if (hoveredStation == s.Index) ctx.font = "bold " + ctx.font;

        ctx.textBaseline = "middle";
        ctx.textAlign = "left";

        var name = s.Name;
        const hasOverrides = trainGraph.InternalTracksClosures.find(c => c.StationId == s.Id);
        if (hasOverrides) name = "[x] " + name;

        ctx.fillText(name, stationNameX, scales.y(i));

        ctx.font = `${fontSizes["large"]} sans-serif`;
        const id = s.Id.indexOf('.') == 2 ? s.Id.substring(3) : s.Id;
        ctx.fillText(id, scales.size.width - margins.right + 5, scales.y(i) - 2);

        ctx.font = `${fontSizes["small"]} sans-serif`;
        ctx.textBaseline = "middle";
        ctx.textAlign = "right";
        ctx.fillText(`${s.Km}`, kmGutterWidth - 5, scales.y(i));
    });
    ctx.restore();


    // Track indicators
    ctx.save();
    ctx.rect(0, margins.top, scales.size.width, scales.size.height - margins.top - margins.bottom);
    ctx.clip();

    // Single track indicator in margin
    ctx.lineWidth = 2;
    ctx.strokeStyle = "rgba(0,0,0,0.75)";
    ctx.beginPath();
    d3.line().context(ctx)([[margins.left + 0.5 - 6, scales.y(0) + 0.5],
    [margins.left + 0.5 - 6, scales.y(trainGraph.SubNetwork.length - 1) + 0.5]]);
    ctx.stroke();
    // Double track indicators in margin
    for (var i = 1; i < trainGraph.SubNetwork.length; i += 1) {
        const thisStationId = trainGraph.Stations[trainGraph.SubNetwork[i].Index].Id;
        const prevStationId = trainGraph.Stations[trainGraph.SubNetwork[i - 1].Index].Id;
        const connections = trainGraph.ConnectionsWithDoubleTrack[prevStationId];
        const hasDoubleTrack = connections && connections[thisStationId];

        if (hasDoubleTrack) {
            ctx.lineWidth = 2;
            ctx.strokeStyle = "rgba(0,0,0,0.75)";
            ctx.beginPath();
            d3.line().context(ctx)([
                [margins.left + 0.5 - 3, scales.y(i) + 0.5],
                [margins.left + 0.5 - 3, scales.y(i - 1) + 0.5]]);
            ctx.stroke();
        }
    }
    ctx.restore();

    // Clipped to train graph area
    ctx.save(); {
        ctx.rect(margins.left, margins.top, scales.size.width - margins.left - margins.right, scales.size.height - margins.top - margins.bottom);
        ctx.clip();


        // Hour lines
        ctx.save();
        ctx.strokeStyle = "rgba(0,0,0,0.25)";
        for (const d of hourLineTimes(scales.x)) {
            ctx.beginPath();
            d3.line().context(ctx)([[scales.x(d) + 0.5, scales.y.range()[0] + 0.5], [scales.x(d) + 0.5, scales.y.range()[1] + 0.5]]);
            ctx.stroke();

        }
        ctx.restore();


        // Now line
        ctx.save();
        ctx.lineWidth = 2;
        ctx.strokeStyle = "rgba(0,0,255,0.5)";
        ctx.fillStyle = "rgba(0,0,255)";
        ctx.beginPath();
        d3.line().context(ctx)([[scales.x(trainGraph.Now) + 0.5, margins.top + 0.5], [scales.x(trainGraph.Now) + 0.5, scales.size.height - margins.bottom + 0.5]]);
        ctx.stroke();
        ctx.font = `${fontSizes["medium"]} sans-serif`;
        ctx.textBaseline = "top";
        const offsetTop = drawParams.drawCanvasHours ? 25 : 5;
        ctx.fillText(displayTimeMinutes(new Date(trainGraph.Now)), scales.x(trainGraph.Now) + 5 + 0.5, margins.top + offsetTop + 0.5);
        ctx.restore();

        ctx.lineWidth = 1;

        const showTimetable = !drawParams || drawParams.showTimetable === true;
        trainGraph.Trains.map(train => {
            if (train.SubNetworkStops.length < 2) return;
            const stopsInSubnetwork = train.Stops.map(s => s.YCoord !== undefined);

            const firstStopInSubnetwork = train.Stops[stopsInSubnetwork.indexOf(true)];
            const lastStopInSubnetwork = train.Stops[stopsInSubnetwork.lastIndexOf(true)];
            var trainDirFactor = 1;
            if (firstStopInSubnetwork && lastStopInSubnetwork)
                trainDirFactor = scales.y(lastStopInSubnetwork.YCoord) - scales.y(firstStopInSubnetwork.YCoord) > 0 ? 1 : -1;

            for (const lineType of lineTypes) {

                if (!showTimetable && lineType.name === "Timetable") continue;
                var firstPlannedTime = lineType.name === "Planned";

                ctx.save();
                const isHovered = hoveredIndex && visits[hoveredIndex].type == "visit" && visits[hoveredIndex].train.Number == train.Number;

                ctx.strokeStyle = lineType.color(train);
                ctx.fillStyle = lineType.color(train);
                if (lineType.dashed) { ctx.setLineDash([10, 5]); }
                else { ctx.setLineDash([]); }

                for (var i = 0; i < train.Stops.length; i += 1) {

                    if (train.Stops[i].YCoord === undefined)
                        continue;

                    const isEntering = isEnteringLine(train, i);
                    const isExiting = isExitingLine(train, i);

                    if (isEntering && isExiting)
                        continue;

                    const stationDistance = Math.abs(scales.y(1) - scales.y(0));
                    const enterExitStubLength = Math.min(10, stationDistance / 2.0);

                    var arrival = lineType.arrival(train.Stops[i]);
                    const departure = lineType.departure(train.Stops[i]);
                    const stationCoord = train.Stops[i].YCoord;

                    if (i > 0) {
                        var prevDeparture = lineType.departure(train.Stops[i - 1]);
                        if (!prevDeparture && firstPlannedTime) {
                            prevDeparture = train.Stops[i - 1].ActualDeparture_date;
                        }

                        const prevStationCoord = train.Stops[i - 1].YCoord;
                        if (prevDeparture && arrival) {
                            const cancelled = train.Stops[i - 1].IsCancelled || train.Stops[i].IsCancelled;

                            // Don't draw actual (or planned) times for cancelled trains.
                            if (cancelled && lineType.name != "Timetable") continue;

                            if (cancelled) {
                                ctx.strokeStyle = "gold";
                                ctx.lineWidth = 2;
                            } else {
                                ctx.strokeStyle = lineType.color(train);
                                ctx.lineWidth = 1;
                            }

                            if (lineType.name == "Actual")
                                ctx.lineWidth = 2;
                            if (isHovered)
                                ctx.lineWidth = 3;


                            ctx.beginPath();
                            ctx.moveTo(scales.x(prevDeparture), scales.y(prevStationCoord));
                            ctx.lineTo(scales.x(arrival), scales.y(stationCoord));
                            ctx.stroke();
                            firstPlannedTime = false;
                        }
                    }

                    if (departure && !arrival && firstPlannedTime) {
                        arrival = train.Stops[i].ActualArrival_date;
                    }

                    if (arrival && departure) {
                        const cancelled = train.Stops[i].IsCancelled;
                        if (cancelled && lineType.name != "Timetable") continue;
                        if (cancelled) {
                            ctx.strokeStyle = "gold";
                            ctx.lineWidth = 2;
                        } else {
                            ctx.strokeStyle = lineType.color(train);
                            ctx.lineWidth = 1;
                        }

                        if (lineType.name == "Actual")
                            ctx.lineWidth = 2;
                        if (isHovered)
                            ctx.lineWidth = 3;

                        ctx.beginPath();
                        ctx.moveTo(scales.x(arrival), scales.y(stationCoord));
                        ctx.lineTo(scales.x(departure), scales.y(stationCoord));
                        ctx.stroke();
                        firstPlannedTime = false;

                        if (isEntering) {
                            ctx.beginPath();
                            ctx.moveTo(scales.x(arrival) - enterExitStubLength, scales.y(stationCoord) - trainDirFactor * enterExitStubLength);
                            ctx.lineTo(scales.x(arrival), scales.y(stationCoord));
                            ctx.stroke();
                        }

                        if (isExiting) {
                            ctx.beginPath();
                            ctx.moveTo(scales.x(departure), scales.y(stationCoord));
                            ctx.lineTo(scales.x(departure) + enterExitStubLength, scales.y(stationCoord) + trainDirFactor * enterExitStubLength);
                            ctx.stroke();
                        }
                    }
                }

                var showTrainLabels = !drawParams || drawParams.trainNumbers === true;
                if (showTrainLabels) {
                    for (var i = lineType.labelStartIdx; i < train.SubNetworkStops.length - 1; i += 7) {
                        const a = train.SubNetworkStops[i];
                        const b = train.SubNetworkStops[i + 1];

                        // If the stops are not adjacent, don't draw here.
                        if (Math.abs(a.YCoord - b.YCoord) != 1) {
                            continue;
                        }

                        // Don't draw actual (or planned) times for cancelled stops.
                        const cancelled = (a.IsCancelled || b.IsCancelled);
                        if (lineType.name != "Timetable" && cancelled) {
                            continue;
                        }
                        const departureA = lineType.departure(a);
                        const arrivalB = lineType.arrival(b);
                        if (!departureA || !arrivalB) {
                            continue;
                        }

                        ctx.save();
                        if (cancelled) {
                            ctx.fillStyle = "gold";
                        }
                        ctx.font = `${fontSizes["medium"]} sans-serif`;
                        const [ptA, ptB] = [[departureA, a.YCoord], [arrivalB, b.YCoord]];
                        const label = { pt: lerp2(ptA, ptB, 0.25), tangent: [ptB[0] - ptA[0], ptB[1] - ptA[1]] };
                        const angle = Math.atan2(scales.y(label.tangent[1]) - scales.y(0),
                            scales.x(label.tangent[0]) - scales.x(0));

                        ctx.translate(scales.x(label.pt[0]), scales.y(label.pt[1]));
                        ctx.rotate(angle);
                        ctx.fillText(train.Number, 0, -2);
                        ctx.restore();
                    }
                }

                ctx.restore();
            }

            {
                const lastStop = train.SubNetworkStops[train.SubNetworkStops.length - 1];
                const arrival = lastStop.PlannedArrival_date || lastStop.ActualArrival_date;
                if (arrival && lastStop.AimedArrival_date) {
                    ctx.save();

                    const delay = arrival - lastStop.AimedArrival_date;
                    const goingUp = lastStop.StationIdx > train.Stops[0].StationIdx;
                    const delayMinutes = Math.round(delay / 1000 / 60);
                    const stationCoord = lastStop.YCoord;
                    const x = scales.x(arrival);
                    const y = scales.y(stationCoord);

                    const isGoods = train.KindTypeAndOperator.startsWith("Gt") ||
                        train.KindTypeAndOperator.startsWith("EGt");
                    const delayThreshold = isGoods ? 6 : 4;

                    var color;
                    if (delayMinutes < 1)
                        color = "green";
                    else if (delayMinutes <= delayThreshold)
                        color = "orange";
                    else
                        color = "red";

                    ctx.fillStyle = color;
                    ctx.font = `${fontSizes["medium"]} sans-serif`;
                    ctx.textAlign = "end";
                    ctx.textBaseline = goingUp ? "bottom" : "top";
                    const yoffset = goingUp ? -3 : 3;
                    ctx.fillText(delayMinutes, x, y + yoffset);

                    ctx.restore();
                }
            }
        });

        // visits
        ctx.save();

        if (isIndex(hoveredIndex) && canHoverVisits) {
            const visit = visits[hoveredIndex];

            if (visit.type == "visit") {
                ctx.strokeStyle = "rgba(255,0,128,1.0)";
                ctx.lineWidth = 3;
                ctx.beginPath();
                ctx.arc(scales.x(visit.pt[0]), scales.y(visit.pt[1]), 8, 0, 2 * Math.PI);
                ctx.stroke();
            } else if (visit.type == "trainLabel") {
                const label = visit.secondaryPointFor ? visit.secondaryPointFor : visit;
                const angle = Math.atan2(scales.y(label.tangent[1]) - scales.y(0),
                    scales.x(label.tangent[0]) - scales.x(0));
                ctx.save();
                ctx.font = `${fontSizes["medium"]} sans-serif`;
                ctx.strokeStyle = label.lineType.color(null);
                if (label.stop.IsCancelled) {
                    ctx.strokeStyle = "gold";
                }
                ctx.translate(scales.x(label.pt[0]), scales.y(label.pt[1]));
                ctx.rotate(angle);
                ctx.lineWidth = 1;
                ctx.strokeText(label.train.Number, 0, -2);
                ctx.globalAlpha = 0.25;
                ctx.lineWidth = 4;
                ctx.strokeText(label.train.Number, 0, -2);
                ctx.restore();
            }
        }

        if (selectedVisits) {
            for (const idx of selectedVisits) {
                const visit = visits[idx];
                if (visit.type == "visit") {
                    ctx.fillStyle = "rgba(255,0,192,0.5)";
                    ctx.beginPath();
                    ctx.arc(scales.x(visit.pt[0]), scales.y(visit.pt[1]), 8, 0, 2 * Math.PI);
                    ctx.fill();
                }
            }
        }

        ctx.restore();
        ctx.restore();
    }
}
