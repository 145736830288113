import { AppState, useAppState } from '@/models/appstate';
import { StationRouting } from '@/models/stationroutingdata';
import { sizeWatch } from '@/utils/dom';
import { twentyFourHoursAxisMultiFormat } from '@/utils/utils';
import * as d3 from 'd3';
import { FunctionalComponent } from 'preact';
import { useState, useEffect, useMemo, useCallback } from 'preact/hooks';
import { fontSizes, stationGraphBaseScales, STATION_GRAPH_MARGINS } from './scales';
import { mkLineRenderBatches, mkPolygonRenderBatchesAlternativeStyle, mkTrainDrawings, mkTrainDrawingsAlternativeStyle } from './renderdata';
import { DrawInfo, drawStationGraph } from './draw';
import { mkGeomIndex } from './geomindex';
import { OverlaySidebar, TrainInfoContents } from './traininfo';

export interface StationGraphProps {
    now: number,
    appState: AppState,
    resetZoom: {},
    stationRouting: StationRouting | null,
}

type Point = [x: number, y: number];

export interface Scales {
    x: d3.ScaleTime<number, number, never>,
    y: d3.ScaleLinear<number, number, never>,
    size: DOMRect,
}


// React component for station train graph.
export const StationGraph: FunctionalComponent<StationGraphProps> = (props) => {
    const stationRouting = props.stationRouting;

    if (stationRouting == null || !props.now || !stationRouting.SolverStatus.Successful) {
        return (
            <div id="grapherrortext">
                <h1>No data available</h1>
                {stationRouting &&
                    <ul>
                        {stationRouting.SolverStatus.Errors.map(msg =>
                            (<li>{msg}</li>))}
                    </ul>
                }
            </div>
        );
    }

    const [divElem, setDivElem] = useState<HTMLElement | null>(null);
    const [canvasElem, setCanvasElem] = useState<HTMLCanvasElement | null>(null);
    const [svgElem, setSvgElem] = useState<SVGSVGElement | null>(null);
    const [divDOMRect, setDivDOMRect] = useState<DOMRect | null>(null);
    const margins = STATION_GRAPH_MARGINS;
    const [transform, setTransform] = useState<d3.ZoomTransform>(d3.zoomIdentity);

    const [pointer, setPointer] = useState<Point | null>(null);

    // Get the size of the canvas.
    useEffect(() => divElem != null ? sizeWatch(divElem, setDivDOMRect) : undefined, [divElem]);

    // Change the size of the canvas
    const scaleFactor = window.devicePixelRatio || 1.0;
    useEffect(() => {
        if (canvasElem == null || svgElem == null || divDOMRect == null) return;
        d3.select(svgElem).attr("width", divDOMRect.width).attr("height", divDOMRect.height);
        d3.select(canvasElem).style("width", divDOMRect.width + "px").style("height", divDOMRect.height + "px");
        var ctx = canvasElem.getContext('2d');
        canvasElem.width = divDOMRect.width * scaleFactor;
        canvasElem.height = divDOMRect.height * scaleFactor;
    }, [canvasElem, svgElem, divDOMRect]);

    // Prepare zoom behavior
    const zoom = useMemo(() => {
        return d3.zoom().on("zoom", (ev: any) => {
            setTransform(ev.transform);
        });
    }, []);

    // Event handlers
    useEffect(() => {
        if (svgElem == null) return;
        const svg = d3.select<Element, unknown>(svgElem);
        svg.on("touchmove mousemove", (e: any) => {
            const svgPt = d3.pointer(e, svgElem);
            setPointer(svgPt);
        });
        svg.call(zoom);

        // svg.on("click", this.click.bind(this));
        // svg.on("dblclick", this.dblClick.bind(this));
    }, [svgElem]);

    // Reset zoom on trigger
    useEffect(() => {
        if (svgElem == null) return;
        const svg = d3.select<Element, unknown>(svgElem);
        svg.transition().duration(750).call(zoom.transform, d3.zoomIdentity);
    }, [props.resetZoom]);

    const baseScales = useMemo(() => {
        if (divDOMRect == null) return null;
        return stationGraphBaseScales(props.now, stationRouting, divDOMRect, margins)
    }, [props.now, props.stationRouting, divDOMRect]);

    const scales: Scales | null = useMemo(() => {
        if (baseScales == null || divDOMRect == null) return null;
        return { ...baseScales, x: transform.rescaleX(baseScales.x0), y: baseScales.y0, size: divDOMRect };
    }, [baseScales, divDOMRect, transform]); // TODO zoom, pan

    // Draw time axis
    useEffect(() => {
        if (svgElem == null || scales == null) return;

        const svg = d3.select(svgElem);
        const axis = svg.select("g#xaxis").node() ?
            svg.select("g#xaxis") as d3.Selection<SVGSVGElement, unknown, null, undefined> :
            svg.append("g").attr("id", "xaxis").attr("transform", `translate(0, ${margins.top})`);
        axis.call(d3.axisTop(scales.x).tickFormat(twentyFourHoursAxisMultiFormat));
    }, [svgElem, scales]);

    const drawInfo: DrawInfo | null = scales != null ? {
        alternativeStyle: props.appState.userSettings.drawParams.alternativeStationGraphStyle,
        showStationEntryExitTimes: props.appState.userSettings.drawParams.showStationEntryExitTimes ?? true,
        drawEastWestArrowInBox: props.appState.userSettings.drawParams.drawEastWestArrowInBox ?? true,
        drawEntryExitWaitTimes: props.appState.userSettings.drawParams.drawEntryExitWaitTimes ?? true,
        margins, scales, now: props.now,
        fontSizes: { medium: "11px sans-serif" },
    } : null;

    // Prepare train drawing objects
    const trainDrawings = useMemo(() => {
        if (drawInfo?.alternativeStyle) {
            return mkTrainDrawingsAlternativeStyle(props.now, stationRouting, "NO.OSL");
        } else {
            return mkTrainDrawings(props.now, stationRouting, "NO.OSL", drawInfo);
        }
    }, [stationRouting, props.appState]);

    // Prepare geometric index
    const pointLookup = useMemo(() => mkGeomIndex(trainDrawings.drawings), [trainDrawings]);

    const hoveredPt: [number, number] | null = useMemo(() => {
        if (scales == null || pointer == null) return null;
        return [
            scales.x.invert(pointer[0]).getTime() - props.now,
            scales.y.invert(pointer[1])
        ];
    }, [scales, pointer])

    const hoveredTrain = useMemo(() => {
        if (pointer == null || hoveredPt == null) return null;
        return pointLookup(hoveredPt);
    }, [hoveredPt, pointLookup]);

    // CANVAS DRAW
    useEffect(() => {
        const ctx = canvasElem?.getContext("2d");
        if (ctx == null || props.stationRouting == null || scales == null || drawInfo == null) return;
        ctx.setTransform(scaleFactor, 0, 0, scaleFactor, 0, 0);

        drawStationGraph(ctx, drawInfo, stationRouting, trainDrawings, hoveredTrain);
    }, [canvasElem, props.stationRouting, scales, hoveredTrain, props.appState]);

    return (
        <div id="stationgraph">
            <div id="canvas" ref={setDivElem}>
                <canvas
                    ref={setCanvasElem}
                    id="traingraphcanvas"
                    style="position:absolute; top:0; left: 0;" />
                <svg
                    ref={setSvgElem}
                    style="position: absolute; top: 0; left: 0;" />
            </div>
            <OverlaySidebar>
                {hoveredTrain != null && <TrainInfoContents trainDwg={hoveredTrain} />}
            </OverlaySidebar>
        </div>);
}
