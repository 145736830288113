import { html } from 'htm/preact';
import { useState } from 'preact/hooks';
import { fetchJson } from './service/fetch';
import { Spinner } from './utils/utils';

// Message div with severity classes and animation on appearance.
function Alert(props: any) {
  if (props.visible == false) {
    return;
  }
  return html`<p
    style="animation: 0.3s ease-out 0s 1 slideInFromTop;"
    class="alert alert-${props.severity}"
  >
    ${props.text}
  </p>`;
}


// Show a login form, send the form to server, and then set the document source
// to "online" if the server accepts the login.
export function LoginScreen({ setDocumentSource, gettext }: any) {
  const [loading, setLoading] = useState(false);
  const [loginFailed, setLoginFailed] = useState(null as string | null);

  // Check if we already have logged in successfully before, and just go straight to the document view.
  if (window.sessionStorage.getItem("loggedin") == "true") {
    setDocumentSource({ type: "online" });
  }

  const login = () => {
    setLoginFailed(null);
    setLoading(true);
    fetchJson(gettext, "/Overrides/LoginAPI", {
      method: 'POST',
      body: new URLSearchParams({
        'username': (document.getElementById("username") as HTMLInputElement).value,
        'password': (document.getElementById("password") as HTMLInputElement).value,
      }),
    })
      .then(r => {
        if (r.Ok) {
          window.sessionStorage.setItem("loggedin", "true");
          setLoginFailed(null);
          setDocumentSource({ type: "online" });
        }
        else {
          setLoginFailed(`${gettext("Login failed")}. ${gettext("Server message")}: ${gettext(r.Error)}`);
        }
      })
      .catch(r => setLoginFailed(`${gettext("Login failed")}: ${r.message}`))
      .finally(() => setLoading(false));
  };

  const loginButtonActive = !loading;

  function submit(e: any) {
    e.preventDefault();
    login();
  }

  return html`
    <div
      class="container"
      style="width: 40rem; animation: 0.3s ease-out 0s 1 slideInFromTop;"
    >
      <section style="text-align:center; margin-top: 6rem;">
        <h2 class="title">${gettext("GoTo dispatching")}</h2>
        <form style="margin-top:3rem;" onSubmit=${submit}>
          <${Alert}
            visible=${!!loginFailed}
            severity="error"
            text="${loginFailed}"
          />
          <input type="text" id="username" placeholder="${gettext("Username")}" /><br />
          <input type="password" id="password" placeholder="${gettext("Password")}" id="password" /><br />
          <input type="submit" value="${gettext("Log in")}" class="button ${loginButtonActive ? "button-primary" : ""} 
                          ${!loginButtonActive ? "button-disabled" : ""}" />
          ${loading && html`<${Spinner} />`}<br />
        </form>
      </section>
    <div class="toolbarbottom">
      <img src="sintef.png" />
      <img src="banenor.png" />
    </div>
    </div>
  `;
}
