export function stationName(id: string): string {
    return stationNameMap.get(id) ?? id.split(".").pop() ?? id;
}

const stationNameMap = new Map<string, string>([
    ["NO.OSL", "Oslo S"],
    ["NO.JAR", "Jaren"],
    ["NO.ROA", "Roa"],
    ["NO.GJØ", "Gjøvik"],
    ["NO.GAR", "Gardermoen"],
    ["NO.DRM", "Drammen"],
    ["NO.HVK", "Høvik"],
    ["NO.SKI", "Ski"],
    ["NO.KBG", "Kongsberg"],
    ["NO.DAL", "Dal"],
    ["NO.EVL", "Eidsvoll"],
    ["NO.LOD", "Lodalen"],
    ["NO.STV", "Stavanger"],
    ["NO.MOS", "Moss"],
    ["NO.HAK", "Hakadal"],
    ["NO.LHM", "Lillehammer"],
    ["NO.SUD", "Sundland"],
    ["NO.ALB","Alnabru"],
    ["NO.HLD","Halden"],
    ["NO.GRE","Grefsen"],
    ["NO.SKN","Skien"],
    ["NO.KVG","Kongsvinger"],
    ["NO.ASR","Asker"],
    ["NO.BES","Bestum"],
    ["NO.MYS","Mysen"],
    ["NO.ÅRN","Årnes"],
]);