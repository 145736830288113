
export function sizeWatch(elem: HTMLElement, cb: (rect: DOMRect) => void): () => void {
    var cancelled = false;
    var lastSize: DOMRect | null = null;
    const onFrame = () => {
        if (cancelled) return;
        const rect = elem.getBoundingClientRect();
        if (lastSize == null || rect.width != lastSize.width || rect.height != lastSize.height) {
            lastSize = rect;
            cb(rect);
        }
        window.requestAnimationFrame(onFrame);
    };
    onFrame();
    return () => { cancelled = true; };
}