import { displayTimeMinutes } from '@/utils/utils';

// Norwegian decription of an override givn in
// the format sent from the server(GuiOverride in C#).
export function describeOverride(ov : any) {
    if (!ov || !ov.Type) return "Ukjent overstyringstype.";
    var time = ov.Time ? displayTimeMinutes(new Date(ov.Time)) : "";
    var train = ov.TrainName;

    switch (ov.Type) {
        case "CancelTrain":
            return `${train} er innstilt.`;
        case "TrainArrivesNotBefore":
            return ov.ChangeTimetableTime ?
                `${train} ankommer ${ov.StationName} i ruteplan ${time}` :
                `${train} ankommer ${ov.StationName} tidligst ${time}`;
        case "TrainLeavesNotBefore":
            return ov.ChangeTimetableTime ?
                `${train} avgår fra ${ov.StationName} i ruteplan ${time}` :
                `${train} avgår fra ${ov.StationName} tidligst ${time}`;
        case "CancelTrainBefore":
            return `${train} er innstilt før ${ov.StationName}`;
        case "CancelTrainAfter":
            return `${train} er innstilt etter ${ov.StationName}`;
        case "TrainsMeet":
            return `${train} og ${ov.OtherTrainName} møtes ved ${ov.StationName}`;
        case "TrainLength":
            return `${train} har lengde ${ov.Length}m.`
        case "InternalTrackClosure":
            return `Spor ${ov.TrackId} ved ${ov.StationName} er stengt.`;
        default:
            return "Ukjent overstyringstype.";
    }
}
