import { html} from 'htm/preact';
import {useState, useEffect} from 'preact/hooks';


export function isIndex(x) {
    return x !== null && x !== undefined && !isNaN(x);
}

const pad = (n) => `${n}`.padStart(2, '0');

export const displayTimeMinutes = (date) => {
    date = new Date(Math.round(date / 1000 / 60) * 60 * 1000);
    return `${pad(date.getHours())}:${pad(date.getMinutes())}`;
};

export const displayTimeSeconds = (date) => {
    date = new Date(Math.round(date / 1000) * 1000);
    return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
};

// Linearly interpolate 2-vectors stored as 2-element arrays.
export const lerp2 = (a, b, t) => [(1 - t) * a[0] + t * b[0], (1 - t) * a[1] + t * b[1]];

export const closeButton = (close) => html`<div style="float:right; margin-left: 1rem;"><a href="#" onclick=${close}><i class="fas fa-times"></i></a></div>`;

// Use like "useState", except that the state is stored in localStorage. This
// function does not watch for changes in the localStorage except for fetching
// the initial value from there, so if there are other uses of the localStore
// with the same key, the values may become out of sync.
export function useLocalStorage(key, defaultValue) {
    const [value, setValue] = useState(() => {
        const initialStoredValue = localStorage.getItem(key);
        const initialValue = (initialStoredValue) ? JSON.parse(initialStoredValue) : defaultValue;
        return initialValue;
    });
    useEffect(() => { localStorage.setItem(key, JSON.stringify(value)); }, [value]);
    return [value, setValue];
}

export function sizeWatch(elem, cb) {
    if (!elem) return;
    var cancelled = false;
    var lastSize = { width: null, height: null };
    const onFrame = (_t) => {
        if (cancelled) return;
        const rect = elem.getBoundingClientRect();
        if (rect.width != lastSize.width || rect.height != lastSize.height) {
            lastSize = rect;
            cb(rect);
        }
        window.requestAnimationFrame(onFrame);
    };
    onFrame(null);
    return () => { cancelled = true; };
}

export function roundTo(value, precision) { return Math.round(value / precision) * precision; }
export function roundDateToMinute(date) {
    return new Date(roundTo(date.getTime(), 1000 * 60));
}

export function useListener(el, ev, f) {
    useEffect(() => {
        el.addEventListener(ev, f);
        return () => el.removeEventListener(ev, f);
    });
}

export function IconButton({ icon, onHover, onClick, enabled, selected, tooltip, styles, badges }) {
    const cls = selected ? " iconselected" : (enabled ? "" : " icondisabled");
    const onMouseOver = onHover && onHover[0];
    const onMouseOut = onHover && onHover[1];
    return html`
      <a onClick=${onClick} href="#">
      <div style="display:inline-block;" class="${tooltip && "tooltip"}">
      ${badges && html`<span class="badgecontainer">${badges}</span>` }
      <i onMouseOver=${onMouseOver} onMouseOut=${onMouseOut} class="${"icon " + icon + cls} ${styles ? styles : ""}" style="font-size: 150%; margin:1rem;"></i>
      ${tooltip && html`<span class="tooltiptext tooltip-right">${tooltip}</span>`}
      </div>
      </a>
`;
}

// Establish the desired formatting options using locale.format():
// https://github.com/d3/d3-time-format/blob/master/README.md#locale_format
var formatMillisecond = d3.timeFormat('.%L'),
    formatSecond = d3.timeFormat(':%S'),
    formatMinute = d3.timeFormat('%H:%M'),
    //formatHour = d3.timeFormat("%H"),
    formatDay = d3.timeFormat('%a %d'),
    formatWeek = d3.timeFormat('%b %d'),
    formatMonth = d3.timeFormat('%B'),
    formatYear = d3.timeFormat('%Y');

// Define filter conditions
export function twentyFourHoursAxisMultiFormat(date) {
    return (d3.timeSecond(date) < date
        ? formatMillisecond
        : d3.timeMinute(date) < date
            ? formatSecond
            : d3.timeDay(date) < date
                ? formatMinute
                : //: d3.timeDay(date) < date ? formatHour
                d3.timeMonth(date) < date
                    ? d3.timeWeek(date) < date
                        ? formatDay
                        : formatWeek
                    : d3.timeYear(date) < date
                        ? formatMonth
                        : formatYear)(date);
}

export function Fade({ show, children }) {
    const [shouldRender, setRender] = useState(show);
    useEffect(() => {
        if (show) setRender(true);
    }, [show]);
    const onAnimationEnd = () => {
        if (!show) setRender(false);
    };
    return (
        shouldRender &&
        html`<div
      style="animation: ${show ? 'fadeIn' : 'fadeOut'}"
      onAnimationEnd=${onAnimationEnd}
    >
      ${children}
    </div>`
    );
}



// Spinner (waiting animation), see CSS.
export function Spinner() {
    return html`<div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>`;
}