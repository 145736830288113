import { AppState } from "@/models/appstate";

/*
 * Call `fetch` and throw if HTTP status is not OK or the body is not JSON.
 * Returns a JSON object.
 */
export async function fetchJson(gettext: ((s: string) => string), input: RequestInfo, init?: RequestInit | undefined, ms = 30000) {
    try {
        const result = await timeout(ms, fetch(input, init));
        if (!result.ok) {
            throw new Error(`${gettext("HTTP error")} ${result.status}, ${gettext(result.statusText)}.`);
        }
        return result.json();
    } catch (e) {
        throw new Error(gettext("Could not reach server."));
    }
}

async function timeout<T>(ms: number, promise: Promise<T>): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
        setTimeout(() => {
            reject(new Error("timeout"))
        }, ms)
        resolve(await promise)
    })
}

export async function sendObject(appState: AppState, url: string, object: any, errorClass: string | null) {
    try {
        const r = await fetchJson(appState.gettext, url, {
            method: 'POST',
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify(object)
        });
        if (r.Error)
            throw new Error(r.Error);
        return r;
    } catch (e) {
        const message = (e instanceof Error) ? e.message : "unknown error";
        return appState.log.error(message, errorClass);
    }
}

export function sendReceiveObject(appState :AppState, addr :string, obj :any) {
    return fetchJson(appState.gettext, addr, {
        method: 'POST',
        headers: { 'Content-Type': "application/json" },
        body: JSON.stringify(obj)
    });
}