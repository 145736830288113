import { html } from 'htm/preact';
import { useState, useEffect, useRef, useCallback } from 'preact/hooks';
import { lerp2, twentyFourHoursAxisMultiFormat, sizeWatch, IconButton, displayTimeMinutes, isIndex } from '@/utils/utils';
import { drawTrainGraph } from './draw';
import { mkTrainLabels, trainVisits } from './labels';

export const margins = { top: 75, right: 50, bottom: 25, left: 175 };
export const defaultZoomInHours = 4;
export const fontSizes = {
    "largeScreen": { "small": "11px", "medium": "13px", "large": "15px" },
    "smallScreen": { "small": "10px", "medium": "11px", "large": "13px" },
};


export function mkBaseScales(trainGraph, size, margins) {
    if (!trainGraph || !trainGraph.Stations || !size) return null;
    const kmExtent = [0 - 1.0, trainGraph.SubNetwork.length];
    const t0 = trainGraph.Now - 0.25 * 3600 * 1000 * defaultZoomInHours;
    const t1 = trainGraph.Now + 0.75 * 3600 * 1000 * defaultZoomInHours;
    const x0 = d3
        .scaleTime()
        .domain([t0, t1])
        .range([margins.left, size.width - margins.right]);
    const y0 = d3
        .scaleLinear()
        .domain(kmExtent)
        .range([size.height - margins.bottom, margins.top]);
    return { x0, y0 };
};


function mkTrainGraph(elem, { setScales, setSelection, setHoveredVisit }) {
    if (!elem) return;
    const div = d3.select(elem);
    const canvas = div.append("canvas").attr("id", "traingraphcanvas").attr("style", "position: absolute; top: 0; left: 0;");
    const svg = div.append("svg").attr("style", "position: absolute; top: 0; left: 0;");
    var ctx = canvas.node().getContext("2d");

    const axis = svg.append("g").attr("id", "xaxis").attr("transform", `translate(0, ${margins.top})`);

    const graph = {
        init() {
            this.fontSizes = fontSizes["smallScreen"];
            this.canHoverVisits = true;
            this.selectedVisits = [];
            this.transform = d3.zoomIdentity;
            const setter = this.setSize.bind(this);
            sizeWatch(elem, (sz) => {
                svg.attr("width", sz.width).attr("height", sz.height);
                canvas.style("width", sz.width + "px").style("height", sz.height + "px");
                const scaleFactor = window.devicePixelRatio || 1.0;
                const canvasDOM = canvas.node();
                var ctx = canvasDOM.getContext('2d');
                canvasDOM.width = Math.ceil(sz.width * scaleFactor);
                canvasDOM.height = Math.ceil(sz.height * scaleFactor);
                ctx.setTransform(scaleFactor, 0, 0, scaleFactor, 0, 0);

                const largeScreen = sz.width > 1200 && sz.height > 800
                if (largeScreen) {
                    this.fontSizes = fontSizes["largeScreen"];
                } else {
                    this.fontSizes = fontSizes["smallScreen"];
                }

                setter(sz);
            });
            this.setZoomAction();
            svg.on("touchmove mousemove", this.setMousePt.bind(this));
            svg.on("mouseleave", this.setMouseOut.bind(this));
            svg.on("click", this.click.bind(this));
            svg.on("dblclick", this.dblClick.bind(this));
        },

        delete() {
            div.selectAll("*").remove();
        },

        findVisit(trainId, stationId) {
            return this.trainStationVisits[trainId] && this.visits[this.trainStationVisits[trainId][stationId]];
        },

        unselect() {
            this.selectedVisits = [];
            setSelection(null);
            this.drawCanvas();
        },

        selectVisits(visits) {
            this.selectedVisits = visits.map(v => {
                if (v.type == "visit") {
                    const train = this.trainStationVisits[v.TrainId];
                    if (!train) return null;
                    return train[v.StationId];
                } else if (v.type == "trainLabel") {
                    const train = this.trainStationLabels[v.TrainId];
                    if (!train) return null;
                    return train[v.StationId];
                }
            }).filter(isIndex);
            if (this.selectedVisits.length > 0) {
                const firstSelected = this.visits[this.selectedVisits[0]];
                if (firstSelected.type == "visit") {
                    setSelection({ type: "visits", visits: this.selectedVisits.map(v => this.visits[v]) });
                } else {
                    setSelection({ ...firstSelected, type: "train" });
                }
            } else {
                setSelection(null);
            }
            this.drawCanvas();
        },

        dblClick() {
            if (this.hoveredIndex) {
                const train = this.visits[this.hoveredIndex].train;
                this.selectedVisits = this.visits.map((obj, idx) => { obj, idx })
                    .filter(({ obj }) => obj.train == train).map(({ idx }) => idx);
                setSelection({ type: "visits", visits: this.selectedVisits.map(v => this.visits[v]) });
                this.drawCanvas();
            }
        },

        click() {
            var update = false;
            if (isIndex(this.hoveredIndex) && this.visits[this.hoveredIndex].type == "trainLabel") {
                this.selectedVisits = [this.hoveredIndex];
                var visit = this.visits[this.hoveredIndex];
                if (visit && visit.secondaryPointFor) visit = visit.secondaryPointFor;
                setSelection({ ...visit, type: "train" });
                this.drawCanvas();
            } else {
                if (isIndex(this.hoveredIndex)) {
                    if (this.selectedVisits.length >= 2 || (this.selectedVisits.length > 0 && this.visits[this.selectedVisits[0]].type == "trainLabel")) {
                        this.selectedVisits = [this.hoveredIndex];
                        update = true;
                    } else {
                        const index = this.selectedVisits.indexOf(this.hoveredIndex);
                        if (index == -1) {
                            this.selectedVisits.push(this.hoveredIndex);
                        } else {
                            this.selectedVisits.splice(index, 1);
                        }
                        update = true;
                    }
                } else if (this.hoveredStation != null) {
                    setSelection({ type: "station", station: this.trainGraph.Stations[this.hoveredStation] });
                    this.selectedVisits = [];
                    this.drawCanvas();
                } else {
                    this.selectedVisits = [];
                    update = true;
                }
                if (update) {
                    setSelection(this.selectedVisits.length > 0 ? { type: "visits", visits: this.selectedVisits.map(v => this.visits[v]) } : null);
                    this.drawCanvas();
                }
            }
        },

        setMouseOut() {
            setHoveredVisit(null);
            this.drawCanvas();
        },

        setMousePt() {
            if (!this.x || !this.y || !this.visitIndex) return;
            const [mouseX, mouseY] = d3.mouse(svg.node());
            const [ptX, ptY] = [this.x.invert(mouseX), this.y.invert(mouseY)];

            if (mouseX < margins.left) {
                this.hoveredIndex = null;
                const prevHovered = this.hoveredStation;

                // In the left gutter, find the nearest station.
                const nearestStation = Math.round(ptY);
                if (nearestStation < 0 || nearestStation >= this.trainGraph.SubNetwork.length) {
                    this.hoveredStation = null;

                } else {
                    this.hoveredStation = this.trainGraph.SubNetwork[nearestStation].Index;
                }

                if (this.hoveredIndex != null || prevHovered != this.hoveredStation) {
                    this.hoveredIndex = null;
                    setHoveredVisit(this.hoveredStation && { type: "station", station: this.trainGraph.Stations[this.hoveredStation] });
                    this.drawCanvas();
                }
            } else {
                this.visitIdx = this.visitIndex.find(this.x0(ptX), this.y0(ptY), this.visitIdx);
                var prevHovered = this.hoveredIndex;
                if (isIndex(this.visitIdx)) {
                    const distance = Math.hypot(mouseX - this.x(this.visits[this.visitIdx].pt[0]), mouseY - this.y(this.visits[this.visitIdx].pt[1]));
                    const isSelectingSecond = (this.selectedVisits || []).length == 1;
                    const fixMeetingOk = !isSelectingSecond || (this.visits[this.visitIdx].pt[1] == this.visits[this.selectedVisits[0]].pt[1]);
                    const hasSelectedSecond = (this.selectedVisits || []).length > 1;

                    if (distance < 25 && !hasSelectedSecond && (!isSelectingSecond || fixMeetingOk)) {
                        this.hoveredIndex = this.visitIdx;
                    } else {
                        this.hoveredIndex = null;
                    }
                } else {
                    this.hoveredIndex = null;
                }

                if (prevHovered != this.hoveredIndex || this.hoveredStation != null) {
                    this.hoveredStation = null;
                    var visit = this.visits[this.hoveredIndex];
                    if (visit && visit.secondaryPointFor) visit = visit.secondaryPointFor;
                    setHoveredVisit(visit);
                    this.drawCanvas();
                }
            }
        },

        setZoomAction() {
            const transformSetter = this.setTransform.bind(this);
            this.zoom = d3.xyzoom()
                .clickDistance(10)
                .on("zoom", (e) => {
                    transformSetter(d3.event.transform);
                });
            const svgElem = svg.node();
            svgElem.__wheelXOnly = (e) => e.clientY && this.size && (e.clientY - this.size.top) < margins.top;
            svgElem.__wheelYOnly = (e) => e.clientX && this.size && (e.clientX - this.size.left) < margins.left;
            svg.call(this.zoom);
            this.resetZoom = () => {
                svg.transition().duration(750).call(this.zoom.transform, d3.xyzoomIdentity);
            };
        },

        setTrainGraph(trainGraph, drawParams) {
            this.trainGraph = trainGraph;
            this.drawParams = drawParams;
            if (!trainGraph.Trains) return;

            const oldVisits = this.selectedVisits.map(idx => {
                const visit = this.visits[idx];
                if (visit.type == "visit") {
                    return { type: visit.type, TrainId: visit.train.Id, StationId: visit.stop.Station.Id };
                } else if (visit.type == "trainLabel") {
                    return { type: visit.type, TrainId: visit.train.Id, StationId: visit.stop.Station.Id };
                }
            });
            this.visits = Array.from(trainVisits(this.trainGraph));
            this.visitIdx = undefined;

            var showTrainLabels = !this.drawParams || this.drawParams.trainNumbers === true;
            var showTimetable = !this.drawParams || this.drawParams.showTimetable === true;
            this.trainLabels = showTrainLabels ? Array.from(mkTrainLabels(this.trainGraph, showTimetable)) : [];

            // TODO rename visits to "clickables" or something like that.
            this.visits.push(...this.trainLabels);

            this.trainStationVisits = {};
            this.visits.map((visit, index) => {
                if (visit.type != "visit") return;
                if (!this.trainStationVisits[visit.train.Id]) this.trainStationVisits[visit.train.Id] = {};
                this.trainStationVisits[visit.train.Id][visit.stop.Station.Id] = index;
            });

            this.trainStationLabels = {};
            this.visits.map((visit, index) => {
                if (visit.type != "trainLabel" || visit.secondaryPointFor) return;
                if (!this.trainStationLabels[visit.train.Id]) this.trainStationLabels[visit.train.Id] = {};
                this.trainStationLabels[visit.train.Id][visit.stop.Station.Id] = index;
            });

            if (oldVisits.length > 0) this.selectVisits(oldVisits);
            this.updateBaseScales();
        },

        setSize(size) {
            this.size = size;
            this.updateBaseScales();
        },

        setTransform(transform) {
            this.transform = transform;
            this.updateScales();
        },

        setCanHoverVisits(b) {
            this.canHoverVisits = b;
            this.drawCanvas();
        },

        updateBaseScales() {
            const baseScales = mkBaseScales(this.trainGraph, this.size, margins);
            if (!baseScales) return;
            this.x0 = baseScales.x0;
            this.y0 = baseScales.y0;

            this.zoom.extent([[this.x0.range()[0], this.y0.range()[0]], [this.x0.range()[1], this.y0.range()[1]]]);
            this.zoom.scaleExtent([[0.25, 15], [0.9, 10]]);
            //this.zoom.translateExtent([[-Infinity, -1], [Infinity, 11]]);

            // The visit index uses the base scales so that points are not so much closer to each other in Y direction than in X.
            this.visitIndex = d3.Delaunay.from(this.visits, d => this.x0(d.pt[0]), d => this.y0(d.pt[1]));
            this.updateScales();
        },

        updateScales() {
            this.x = this.transform.rescaleX(this.x0);
            this.y = this.transform.rescaleY(this.y0);
            axis.call(d3.axisTop(this.x).tickFormat(twentyFourHoursAxisMultiFormat));
            this.drawCanvas();
            setScales({ x: this.x, y: this.y, sz: this.size });
        },

        drawCanvas() {
            drawTrainGraph({
                ctx, margins,
                trainGraph: this.trainGraph,
                scales: { x: this.x, y: this.y, size: this.size },
                drawParams: this.drawParams,
                fontSizes: this.fontSizes,
                hoveredStation: this.hoveredStation,
                hoveredIndex: this.hoveredIndex,
                canHoverVisits: this.canHoverVisits,
                visits: this.visits,
                selectedVisits: this.selectedVisits,
            });
        }
    };

    graph.init();
    return graph;
}

function canFixMeeting(selection) {
    if (selection && selection.type == "visits" && selection.visits.length == 2) {
        return selection.visits[0].stop.Station.Index == selection.visits[1].stop.Station.Index;
    }
    return false;
}

function singleVisit(selection) {
    return selection && selection.type == "visits" && selection.visits.length == 1;
}

export function TrainGraph({ trainGraph, resetZoom, overrideSetters, appState, viewType }) {
    if (!trainGraph) return null;

    const [scales, setScales] = useState(null);
    const [selection, setSelection] = useState(null);
    const [trainFixMeetingMenu, setTrainFixMeetingMenu] = useState(null);
    const [hoveredVisit, setHoveredVisit] = useState(null);
    const [tempTooltip, setTempTooltip] = useState(null);
    const setters = { setScales, setSelection, setHoveredVisit };
    const graphRef = useRef();
    const setCanvasContainerElement = useCallback((e) => {

        if (graphRef.current)
            graphRef.current.delete();

        graphRef.current = mkTrainGraph(e, setters);

        if (graphRef.current)
            graphRef.current.setTrainGraph(trainGraph, appState.userSettings.drawParams);

        resetZoom.current = (graphRef.current || {}).resetZoom;
    }, [viewType]);

    useEffect(() => graphRef.current.setTrainGraph(trainGraph, appState.userSettings.drawParams),
        [trainGraph, appState.userSettings.drawParams]);

    const selectionCanHoverVisits = (selection) =>
        !selection || selection.type === "visits" || selection.type === "train";

    useEffect(() => graphRef.current.setCanHoverVisits(selectionCanHoverVisits(selection)),
        [selection]);

    const selectVisits = (visits) => {
        if (graphRef.current) graphRef.current.selectVisits(visits);
    };
    const reactSetSelection = useCallback((s) => {
        setTrainFixMeetingMenu(null);
        if (graphRef.current) graphRef.current.unselect();
        setSelection(s);
    });

    const ovSet = overrideSetters(appState);
    const overlays = [];
    var tooltip = null;
    const closeMenu = () => { reactSetSelection(null); };

    // Clear the train fix meeting menu flag if it is not in use.
    if (trainFixMeetingMenu != null) {
        const relevant = singleVisit(selection) && trainFixMeetingMenu == selection.visits[0].train.Id;
        if (!relevant) {
            setTrainFixMeetingMenu(null);
        }
    }

    if (!selection) {
        if (hoveredVisit && hoveredVisit.type == "visit" && scales) {
            tooltip = html`<${ToolTip} size=${scales.sz} pt=${[scales.x(hoveredVisit.pt[0]), scales.y(hoveredVisit.pt[1])]}>
                        <${VisitToolTip} hoveredVisit=${hoveredVisit} />
                       </${ToolTip}>`;
        } else if (hoveredVisit && hoveredVisit.type == "station" && scales) {
            const pt = [margins.left, scales.y(trainGraph.SubNetworkIndices.get(hoveredVisit.station.Index))];
            tooltip = html`<${ToolTip} size=${scales.sz} pt=${pt} side="right">
                        <${StationToolTip} hideButtons=${true} overrideSetters=${ovSet} trainGraph=${trainGraph} log=${appState.log} station=${hoveredVisit.station} />
                            </${ToolTip}>`;
        } else if (hoveredVisit && hoveredVisit.type == "trainLabel" && scales) {
            const pt = [scales.x(hoveredVisit.pt[0]), scales.y(hoveredVisit.pt[1])];
            tooltip = html`<${ToolTip} size=${scales.sz} pt=${pt} side="left">
                                    <${TrainDescription} train=${hoveredVisit.train}/>
                            </${ToolTip}>`;
        }

        if (tempTooltip)
            tooltip = tempTooltip;
    } else if (selection.type == "train") {
        tooltip = html`<${MenuOverUnder} scales=${scales} pt=${selection.pt} close=${closeMenu}>
                            <${TrainMenu} selection=${selection} overrideSetters=${ovSet} 
                                trainGraph=${trainGraph} log=${appState.log} setSelection=${reactSetSelection}
                                edit=${true}
                            />
                        </${MenuOverUnder}>`;
    } else if (selection.type == "station") {
        const pt = [margins.left, scales.y(trainGraph.SubNetworkIndices.get(selection.station.Index))];
        tooltip = html`<${ToolTip} size=${scales.sz} pt=${pt} side="right" clickable="true">
                        <${StationToolTip} overrideSetters=${ovSet} trainGraph=${trainGraph} station=${selection.station} log=${appState.log} close=${() => reactSetSelection(null)} />
                            </${ToolTip}>`;
    } else if (singleVisit(selection)) {
        if (hoveredVisit && hoveredVisit.type == "visit" && hoveredVisit.train.Id != selection.visits[0].train.Id) {
            overlays.push(FixMeetingLine({ scales, visit1: selection.visits[0], visit2: hoveredVisit }));
        }
        var contents;
        const visit = selection.visits[0];
        if (trainFixMeetingMenu == visit.train.Id) {
            contents = html`Velg tog som møter <br /><b>${visit.train.Number} ved ${visit.stop.Station.Name}</b><br />`;
        } else {
            contents = html`<${VisitMenu} selection=${selection} overrideSetters=${ovSet} 
                            setTrainFixMeetingMenu=${setTrainFixMeetingMenu}
                            trainGraph=${trainGraph} log=${appState.log} setSelection=${reactSetSelection}/>`;
        }
        tooltip = html`<${MenuOverUnder} scales=${scales} pt=${visit.pt} close=${closeMenu}>
                        ${contents}
                       </${MenuOverUnder}>`;
    } else if (canFixMeeting(selection)) {
        const [line, menu, pt] = FixMeeting({ scales, selection, overrideSetters: ovSet, fixedMeetings: trainGraph.FixedMeetings, log: appState.log });
        tooltip = html`<${MenuOverUnder} scales=${scales} pt=${pt} close=${closeMenu}>${menu}</${MenuOverUnder}>`;
        overlays.push(line);
    } else if (selection.type == "setDepartureTime" || selection.type == "setArrivalTime") {
        const advanced = appState.userSettings.advanced;
        const [symbol, menu, pt] = ArrivalDepartureTime({ advanced, scales, selection, setSelection: reactSetSelection, overrideSetters: ovSet, log: appState.log });
        tooltip = html`<${MenuOverUnder} scales=${scales} pt=${pt} close=${closeMenu}>
                        ${menu}
                       </${MenuOverUnder}>`;
        overlays.push(symbol);
    } else if (selection.type == "setTrainLength") {
        const [symbol, menu, pt] = SetTrainLength({ scales, selection, setSelection: reactSetSelection, overrideSetters: ovSet, log: appState.log });
        tooltip = html`<${MenuOverUnder} scales=${scales} pt=${pt} close=${closeMenu}>${menu}</${MenuOverUnder}>`;
        overlays.push(symbol);
    }

    const firstArrivalLines = FirstArrivalLines({ scales, trainGraph, graph: graphRef.current });
    overlays.push(...(firstArrivalLines || []));
    const arrivalDepartureLines = ArrivalDepartureLines({ scales, trainGraph, selection, graph: graphRef.current, setSelection: reactSetSelection });
    overlays.push(...(arrivalDepartureLines || []));

    const arrivalArrows = FirstArrivalArrows({ scales, trainGraph, graph: graphRef.current, setSelection: reactSetSelection, selection });
    overlays.push(...(arrivalArrows || []));



    const clipWidth = scales && scales.sz && scales.sz.width - margins.left - margins.right;
    const clipHeight = scales && scales.sz && scales.sz.width - margins.top - margins.bottom;
    return html`
    <div id="traingraph">
      <div id="canvas" ref=${setCanvasContainerElement}></div>
      <svg id="overlayssvg" style="pointer-events: none;">
        <defs>
            <marker id='head' orient='auto' markerWidth='2' markerHeight='4'
                    refX='2' refY='2'>
                <path d='M0,0 V4 L2,2 Z' style="fill: purple;"/>
                <clipPath id="graphareaclip">
                    <rect x=${margins.left} y=${margins.top} 
                    width=${clipWidth} 
                    height=${clipHeight} />
                </clipPath>
            </marker>
            <marker id='headred' orient='auto' markerWidth='2' markerHeight='4'
                    refX='2' refY='2'>
                <path d='M0,0 V4 L2,2 Z' style="fill: red;" />
                <clipPath id="graphareaclip">
                    <rect x=${margins.left} y=${margins.top} 
                    width=${clipWidth} 
                    height=${clipHeight} />
                </clipPath>
            </marker>
        </defs>
        <g clip-path="url(#graphareaclip)">
          ${overlays}
        </g>
      </svg>
      <div style="pointer-events: none; position: absolute;
            top:0px; left: 0px;
            width: ${scales && scales.sz.width || 100}px; 
            height: ${scales && scales.sz.height || 100}px; 
            clip-path: inset(${margins.top}px ${margins.right}px 
                            ${margins.bottom}px ${margins.left}px);">
      <${FixedMeetingIcons} scales=${scales} fixedMeetings=${trainGraph.FixedMeetings} selectVisits=${selectVisits} graph=${graphRef.current} />
      <${SilentTrainWarning} ...${{ scales, trainGraph, selectVisits, setTempTooltip, gettext: appState.gettext }} />
      </div>

      ${tooltip}
    </div>`;
}


const firstArrivalLinesDelayThreshold = 15; // minutes
function FirstArrivalLines({ scales, trainGraph, graph }) {
    if (!scales || !trainGraph || !graph) return;
    return (trainGraph.Trains || []).filter(train => train.SubNetworkStops.length >= 2).map(train => {
        const trainDir = train.SubNetworkStops[0].Station.Index < train.SubNetworkStops[1].Station.Index ? 1.0 : -1.0;
        const firstStop = train.SubNetworkStops[0];

        const t1 = firstStop.AimedArrival_date;
        const t2 = firstStop.ActualArrival_date || firstStop.PlannedArrival_date;
        if (!t1 || !t2) return;
        if (t2 - t1 < firstArrivalLinesDelayThreshold * 60 * 1000) return;

        const x1 = firstStop.YCoord;
        const nextStation = x1 + trainDir;
        const x2 = x1 + -(nextStation - x1) * 0.25;
        const path = d3.line().x(pt => scales.x(pt[0])).y(pt => scales.y(pt[1]))
            ([[t1, x1], [t1, x2], [t2, x2], [t2, x1]]);

        return html`<path class="latetrainarrivalline" marker-end="url(#headred)" d=${path} />`;
    });
}

function ArrivalDepartureLines({ scales, trainGraph, graph, selection, setSelection }) {
    if (!scales || !trainGraph || !graph) return;
    const items = [];
    if (trainGraph.TrainNotBefore.Arrives) items.push(...trainGraph.TrainNotBefore.Arrives);
    if (trainGraph.TrainNotBefore.Departs) items.push(...trainGraph.TrainNotBefore.Departs);
    return items.map(ov => {
        const visit = graph.findVisit(ov.TrainId, ov.StationId);
        if (!visit || visit.train.SubNetworkStops.length < 2) return;
        const departure = ov.Type === "TrainLeavesNotBefore";
        const selectionType = departure ? "setDepartureTime" : "setArrivalTime";
        var visible = true;
        if (selection && selection.type === selectionType) {
            if (selection.data.TrainId == visit.train.Id && selection.data.StationId == visit.stop.Station.Id) {
                visible = false;
            }
        }
        var t1 = ov.Time - ov.Duration;
        var t2 = ov.Time;

        var symbol = null;
        const trainDir = Math.sign(visit.train.Stops[1].Station.Index - visit.train.Stops[0].Station.Index);
        const x1 = t1;
        const y1 = visit.pt[1];
        const x2 = x1;
        const y2 = y1 - 0.25 * trainDir;
        const x3 = t2;
        const y3 = y2;
        const x4 = x3;
        const y4 = y1;

        if (visible) {
            const path = d3.line().x(d => scales.x(d[0])).y(d => scales.y(d[1]))([[x1, y1], [x2, y2], [x3, y3], [x4, y4]]);
            symbol = html`<path class="delayoverrideline" marker-end='url(#head)' d="${path}" />`;
        }

        const drag = useCallback((d) => {
            var newTime = scales.x.invert(d3.event.x);
            var newOffset = newTime - t1;
            setSelection({ type: selectionType, data: { ...ov, Time: newTime.getTime(), Duration: newOffset }, visit, originalData: ov });
        });

        const click = useCallback((d) => {
            setSelection({ type: selectionType, data: ov, visit, originalData: ov });
        })

        const makeDraggable = useCallback((e) => {
            d3.select(e).on("click", click).call(d3.drag().on("drag", drag));
        });

        const dragY = Math.min(scales.y(y3), scales.y(y4));
        const height = Math.abs(scales.y(y3) - scales.y(y4));


        return html`
            ${symbol}
            <rect x=${scales.x(x4) - 10} y=${dragY} width=20 height=${height} pointer-events="all" ref=${makeDraggable} style="fill-opacity: 0; fill: black; stroke-opacity: 0; cursor: e-resize;"  />
        `;
    });
}

function SilentTrainWarning({ scales, trainGraph, selectVisits, setTempTooltip, gettext }) {
    if (!scales || !trainGraph) return;

    return (trainGraph.Trains || [])
        .filter(silentTrainFilter)
        .map(train => {
            const stop = train.SubNetworkStops.find(v => !v.ActualArrival_date || !v.ActualDeparture_date);
            const lastActualStop = train.SubNetworkStops.find((v, i) => i + 1 == train.SubNetworkStops.length /* last stop */ ||
                (i + 1 < train.SubNetworkStops.length /* any stop where the next stop has no actual arrival or departure time */
                    && (!train.SubNetworkStops[i + 1].ActualArrival_date && !train.SubNetworkStops[i + 1].ActualDeparture_date)));

            const lastActualTime = lastActualStop &&
                (lastActualStop.ActualDeparture_date || lastActualStop.ActualArrival_date);
            const anyPlannedStop = train.SubNetworkStops.find(v => v.PlannedArrival_date || v.PlannedDeparture_date);

            if (!stop) return;
            const onClick = () => {
                selectVisits([{ type: "visit", TrainId: train.Id, StationId: stop.StationId }]);
            };

            const goingUp = train.SubNetworkStops[train.SubNetworkStops.length - 1].StationIdx > train.SubNetworkStops[0].StationIdx;
            const yOffset = 10;

            const x = scales.x(lastActualTime || stop.AimedArrival_date);
            const y = scales.y((lastActualStop || stop).YCoord) + (goingUp ? -1 : 1) * yOffset;
            const yInv = scales.sz.height - y;

            var msg = gettext("No real-time information");
            if (lastActualTime) {
                const age = trainGraph.Now - lastActualTime;
                const diffMinutes = Math.round(age / (1000 * 60));
                msg += ` ${gettext("since")} ${displayTimeMinutes(new Date(lastActualTime))} (${diffMinutes} min).`;
            } else {
                msg += `${gettext(" yet")}.`;
            }
            const isPlannedMsg = anyPlannedStop !== undefined && gettext("Actual train times are estimated.");
            const tooltipContents = html`<p style="margin-bottom: 0;"><b>Tog ${train.Number}</b></p>${msg}
                ${isPlannedMsg && html`<br />${isPlannedMsg}`}`;

            const onMouseOver = useCallback(() => {
                setTempTooltip(html`<${ToolTip} size=${scales.sz} pt=${[x + 25, y + 25]} side="right">${tooltipContents}</${ToolTip}>`);
            });
            const onMouseOut = useCallback(() => setTempTooltip(null));

            return html`
                <div class="visittooltip" style="position: absolute; left: ${x}px; bottom: ${yInv}px; pointer-events: none;" >
                    <div style="position: relative; font-size: 75%;  transform: translateY(50%); pointer-events: all;">
                        <${IconButton} icon="fas fa-exclamation-triangle" onHover=${[onMouseOver, onMouseOut]} onClick=${onClick} enabled="true" styles="canvasicon" pointer-events="all"/>
                    </div>
                </div>`;
        });
}

const silentTrainFilter = train => train.HasGoneSilent
    && !train.KindTypeAndOperator.startsWith("At") // No planning is done for service trains (At).
    && train.SubNetworkStops.length >= 2
    && !train.SubNetworkStops[0].IsCancelled
    ;

function FirstArrivalArrows({ scales, trainGraph, graph, setSelection, selection }) {
    return;
    if (!scales || !trainGraph || !graph) return;
    return (trainGraph.Trains || [])
        .filter(silentTrainFilter)
        .filter(train => !train.Stops.some(s => s.ActualArrival_date || s.ActualDeparture_date))
        .map(train => {
            const stop = train.Stops[0];
            const timetableTime = stop.AimedArrival_date;

            const hasArrivalTime = (trainGraph.TrainNotBefore.Arrives || [])
                .find(c => c.TrainId == train.Id && c.StationId == stop.Station.Id);

            var visible = true;
            // hide the arrow if there is an override (the override will draw a smaller arrow)
            if (hasArrivalTime) visible = false;

            var currentTime;
            if (selection && selection.type == "setArrivalTime" && selection.data.TrainId == train.Id && selection.data.StationId == stop.Station.Id) {
                currentTime = selection.data.Time;
            } else if (hasArrivalTime) {
                currentTime = hasArrivalTime.Time;
            } else {
                currentTime = timetableTime;
            }

            const visit = {
                train: train,
                stop: stop,
                pt: [currentTime, stop.YCoord],
            };
            const data = hasArrivalTime || { TrainId: train.Id, StationId: stop.Station.Id, Time: timetableTime, Duration: 0, };

            const drag = useCallback((d) => {
                var newTime = scales.x.invert(d3.event.x);
                var newOffset = newTime - timetableTime;
                setSelection({ type: "setArrivalTime", data: { ...data, Time: newTime.getTime(), Duration: newOffset }, visit, originalData: hasArrivalTime });
            });

            const click = useCallback((d) => {
                setSelection({ type: "setArrivalTime", data, visit, originalData: hasArrivalTime });
            })

            const makeDraggable = useCallback((e) => {
                d3.select(e).on("click", click).call(d3.drag().on("drag", drag));
            });

            const minimumArrowHeight = 20;
            const trainDir = Math.sign(train.Stops[1].Station.Index - train.Stops[0].Station.Index);
            const x3 = scales.x(visit.pt[0]);
            var y3 = scales.y(visit.pt[1] - 0.4 * trainDir);
            const x4 = scales.x(visit.pt[0]);
            const y4 = scales.y(visit.pt[1]);
            if (Math.abs(y3 - y4) < minimumArrowHeight) {
                y3 = y4 + (y3 - y4) / Math.abs(y3 - y4) * minimumArrowHeight;
            }
            const path = d3.line().x(d => d[0]).y(d => d[1])([[x3, y3], [x4, y4]]);
            var symbol = null;
            if (visible) {
                symbol = html`<path class="delayoverrideline firstarrivalarrow" marker-end='url(#head)' d="${path}" />`;
            }
            const height = Math.abs(y3 - y4);
            return html`
                ${symbol}
                <rect x=${x4 - 20} y=${Math.min(y3, y4)} width=40 height=${height} pointer-events="all" ref=${makeDraggable} style="fill-opacity: 0; fill: black; stroke-opacity: 0; cursor: e-resize;"  />
            `;
        });
}

function SetTrainLength({ scales, selection, setSelection, overrideSetters, log }) {
    const clickCancel = useCallback(() => { setSelection(null); });
    const clickDelete = useCallback(() => {
        log.info({ message: `Fjernet overstyring av toglengde for ${selection.originalData.TrainId} til ${selection.originalData.Length}.` });
        overrideSetters.deleteOverride({ Type: "TrainLength", ...selection.originalData });
        setSelection(null);
    });
    const clickSend = useCallback(() => {
        log.info({ message: `Overstyrer toglengde for ${selection.data.TrainId} til ${selection.data.Length}.` });
        overrideSetters.newOverride({ Type: "TrainLength", ...selection.data });
        setSelection(null);
    });

    const setLength = useCallback((event) => {
        const Length = parseInt(event.target.value) || 0;
        setSelection({ ...selection, data: { ...selection.data, Length } });
    });
    const cancelButton = selection.originalData && html`
        <input style="margin: 0.5rem;" type="button" class="button button-primary" value="Fjerne" onClick=${clickDelete}/>`;

    const menu = html`
        <p><b>Tog ${selection.train.Number}</b></p>
        Lengde: <input type="text" style="width: 20rem;" value=${selection.data.Length} onInput=${setLength} /> m
        <p style="text-align: right;">
        <input style="margin: 0.5rem;" type="button" class="button" value="Avbryt" onClick=${clickCancel}/>
        <input style="margin: 0.5rem;" type="button" class="button button-primary" value="OK" onClick=${clickSend}/>
        ${cancelButton}
        </p>
        `;
    const symbol = null;
    const pt = selection.pt;
    return [symbol, menu, pt];
}

function ArrivalDepartureTime({ advanced, scales, selection, setSelection, overrideSetters, log }) {
    const departure = (selection.type == "setDepartureTime") ? true : false;
    const text = departure ? "Tidligste avgang" : "Tidligste ankomst";
    const preposition = departure ? "fra" : "til";
    const originalTimetableTime = departure ? selection.visit.stop.AimedDeparture_date : selection.visit.stop.AimedArrival_date;
    const originalTime = selection.data.Time - (selection.data.Duration || 0);
    const serverOverrideType = departure ? "TrainLeavesNotBefore" : "TrainArrivesNotBefore";

    if (isNaN(new Date(selection.data.Time))) selection.data.Time = selection.visit.pt[0];
    const diffMinutes = Math.round((selection.data.Time - originalTime) / (1000 * 60));
    const diff = (diffMinutes >= 0) ? html`<b>${diffMinutes}</b> min etter rutetid.` : html`<b>${Math.abs(diffMinutes)} min</b> før rutetid.`;
    const setHours = useCallback((event) => {
        const newTime = new Date(selection.data.Time);
        newTime.setHours(event.target.value);
        const newOffset = newTime - originalTime;
        setSelection({ ...selection, data: { ...selection.data, Time: newTime.getTime(), Duration: newOffset } });
    });
    const setMinutes = useCallback((event) => {
        const newTime = new Date(selection.data.Time);
        newTime.setMinutes(event.target.value);
        const newOffset = newTime - originalTime;
        setSelection({ ...selection, data: { ...selection.data, Time: newTime.getTime(), Duration: newOffset } });
    });
    const setChangeTimetableTime = useCallback((event) => {
        var newValue = event.target.checked ?? false;
        setSelection({ ...selection, data: { ...selection.data, ChangeTimetableTime: newValue } });
    });
    const clickCancel = useCallback(() => { setSelection(null); });
    const clickDelete = useCallback(() => {
        log.info({ message: `Fjernet ${text.toLowerCase()} for ${selection.originalData.TrainId} ved ${selection.originalData.StationId}.` });
        overrideSetters.deleteOverride({ Type: serverOverrideType, ...selection.originalData });
        setSelection(null);
    });
    const clickSend = useCallback(() => {
        log.info({ message: `Satt ${text.toLowerCase()} for ${selection.data.TrainId} ved ${selection.data.StationId}.` });
        overrideSetters.newOverride({ Type: serverOverrideType, ...selection.data });
        setSelection(null);
    });
    const pt = [selection.data.Time, selection.visit.pt[1]];

    const drag = useCallback((d) => {
        const newTime = scales.x.invert(d3.event.x);
        const newOffset = newTime - originalTime;
        setSelection({ ...selection, data: { ...selection.data, Time: newTime.getTime(), Duration: newOffset } });
    });
    const makeDraggable = useCallback((e) => {
        d3.select(e).call(d3.drag().on("drag", drag));
    });

    const cancelButton = selection.originalData && html`
        <input style="margin: 0.5rem;" type="button" class="button button-primary" value="Fjerne" onClick=${clickDelete}/>`;

    const trainDir = Math.sign(selection.visit.train.Stops[1].Station.Index - selection.visit.train.Stops[0].Station.Index);
    const x1 = originalTime;
    const y1 = selection.visit.pt[1];
    const x2 = x1;
    const y2 = y1 - 0.25 * trainDir;
    const x3 = pt[0];
    const y3 = y2;
    const x4 = x3;
    const y4 = y1;

    const path = d3.line().x(d => scales.x(d[0])).y(d => scales.y(d[1]))([[x1, y1], [x2, y2], [x3, y3], [x4, y4]]);

    const dragY = Math.min(scales.y(y3), scales.y(y4));
    const height = Math.abs(scales.y(y3) - scales.y(y4));

    const symbol = html`
        <path class="delayoverrideline"  marker-end='url(#head)' d="${path}" />
        <rect x=${scales.x(x4) - 10} y=${dragY} width=20 height=${height} pointer-events="all" ref=${makeDraggable} style="fill-opacity: 0; fill: black; stroke-opacity: 0; cursor: e-resize;"  />
    `;

    // const showChangeTimetableTime = advanced && advanced !== "false";
    const showChangeTimetableTime = false;

    const menu = html`
        <p>${text} for <b>${selection.visit.train.Number}</b> ${preposition} <b>${selection.visit.stop.Station.Name}</b></p>
        <p><i class="icon far fa-clock canvasicon" style="font-size: 150%; margin:1rem;"></i>
        <input type="text" style="width: 6rem;" value=${`${(new Date(selection.data.Time)).getHours()}`.padStart(2, '0')} onInput=${setHours} />
        :
        <input type="text" style="width: 6rem;" value=${`${(new Date(selection.data.Time)).getMinutes()}`.padStart(2, '0')} onInput=${setMinutes} /><br />
        ${diff}</p>
        ${showChangeTimetableTime && html`<p><label><input type="checkbox" checked=${selection.data.ChangeTimetableTime} onInput=${setChangeTimetableTime} /> Endre ruteplantid</label></p>`}
        <p style="text-align: right;">
        <input style="margin: 0.5rem;" type="button" class="button" value="Avbryt" onClick=${clickCancel}/>
        <input style="margin: 0.5rem;" type="button" class="button button-primary" value="OK" onClick=${clickSend}/>
        ${cancelButton}
        </p>
        `;

    return [symbol, menu, pt];
}

function FixedMeetingIcons({ graph, scales, fixedMeetings, selectVisits }) {
    if (!scales || !fixedMeetings || !graph) return;
    return fixedMeetings.map(meet => {
        const v1 = graph.findVisit(meet.TrainId, meet.StationId);
        const v2 = graph.findVisit(meet.OtherTrainId, meet.StationId);
        if (!v1 || !v2) return;
        const x = scales.x(lerp2(v1.pt, v2.pt, 0.5)[0]);
        const y = (scales.sz.height - (scales.y(v1.pt[1] + 0.02)));
        const onClick = () => {
            selectVisits([{ type: "visit", TrainId: meet.TrainId, StationId: meet.StationId },
            { type: "visit", TrainId: meet.OtherTrainId, StationId: meet.StationId }]);
        };
        return html`
        <div class="visittooltip" style="position: absolute; left: ${x}px; bottom: ${y}px; pointer-events: none;" >
            <div style="position: relative; left: -50%; font-size: 75%; pointer-events: all;">
                <${IconButton} icon="fas fa-lock" onClick=${onClick} enabled="true" styles="canvasicon" pointer-events="all"/>
            </div>
        </div>`;
    })
}

function TrainDescription({ train }) {
    var originDestination = "";
    if (train.RealOriginId || train.RealDestinationId) {
        const from = (train.RealOriginId || "???").split(".").pop();
        const to = (train.RealDestinationId || "???").split(".").pop();
        originDestination = `(${from}-${to})`;
    }

    const rollingStockLengthMatches = (train.RollingStocks || []).length >= 1 &&
        train.RollingStocks.every(r => r.Length == train.Length);
    const lengthIsOverride = !rollingStockLengthMatches &&
        (train.RollingStocks || []).length >= 1 &&
        !(train.RollingStocks || []).some(r => r.Length == train.Length);
    const lengthIsEstimated = (train.RollingStocks || []).length === 0;

    var lengthDescription = null;
    if (!rollingStockLengthMatches)
        lengthDescription = html`${lengthIsOverride ? "Overstyrt lengde" : (lengthIsEstimated ? "Estimert lengde" : "Lengde")}: ${train.Length || "ukjent"}${train.Length ? "m" : ""}<br />`;

    var rollingStockDescription = (train.RollingStocks || []).map(r => {

        const from = (r.FromStationId ?? "???").split(".").pop();
        const to = (r.ToStationId ?? "???").split(".").pop();
        var dest = `(${from}-${to})`;

        // No need to show the origin and destination if they match the train .
        const showDest = (train.RollingStocks || []).length > 1 || dest != originDestination;

        const title = `Vognopptak${showDest && ` ${dest}` || ""}:`;
        return html`
        <div class="vognopptak">
            ${title}
            <ul class="vognopptak">
            <li class="vognopptak"> Lengde: ${r.Length}m</li>
            <li class="vognopptak"> Vekt: ${r.Weight}t</li>
            <li class="vognopptak"> Lok.: ${(r.TractionUnits || []).join(", ") || "ukjent"} (${(r.WagonUnits || []).length || "ukjent ant."} vogner) </li>
            </ul>
        </div>`;
    });

    if ((train.RollingStocks || []).length == 0)
        rollingStockDescription = html`<div class="vognopptak">Ukjent vognopptak.</div>`;

    return html`<b>Tog ${train.Number}</b> ${originDestination}<br />${train.KindTypeAndOperator}<br />${lengthDescription}${rollingStockDescription}`;
}

function TrainMenu({ scales, selection, setSelection, overrideSetters, trainGraph, log, edit }) {
    const cancellations = trainGraph.Cancellations;
    const haveCancel = cancellations.full.find(c => c.TrainId == selection.train.Id);
    const cancelStyle = haveCancel ? "canvasiconselected" : "canvasicon";
    const cancelTitle = !haveCancel ? "Innstill tog" : "Fjerne innstilling";
    const onClickCancel = useCallback(() => {
        if (haveCancel) {
            log.info({ message: `Fjernet innstilling av ${selection.train.Number}.` });
            overrideSetters.deleteOverride(haveCancel);
        }
        else {
            log.info({ message: `Opprettet innstilling av ${selection.train.Number}.` });
            overrideSetters.newOverride({
                Type: "CancelTrain",
                TrainId: selection.train.Id,
            });
        }
    });

    const haveLengthOverride = (trainGraph.TrainLengthOverrides || []).find(c => c.TrainId == selection.train.Id);
    const lengthStyle = haveLengthOverride ? "canvasiconselected" : "canvasicon";

    const onClickLength = () => {
        const data = haveLengthOverride || { TrainId: selection.train.Id, Length: 100.0 };
        setSelection({ type: "setTrainLength", data, pt: selection.pt, train: selection.train, originalData: haveLengthOverride });
    };


    const canCancel = !(selection.train.IsCancelled && !haveCancel);
    const cancelItem = html`<${MenuItem} icon="far fa-stop-circle" enabled="true" styles=${cancelStyle} onClick=${onClickCancel} label="${cancelTitle}" />`;
    const lengthItem = html`<${MenuItem} icon="fas fa-ruler-horizontal" enabled="true" styles=${lengthStyle} onClick=${onClickLength} label="Sett toglendge" />`;

    return html`<${TrainDescription} train=${selection.train}/>
        ${canCancel && cancelItem}
        ${lengthItem}
        `;

}

function VisitMenu({ scales, selection, setSelection, overrideSetters, trainGraph, log, setTrainFixMeetingMenu }) {
    const cancellations = trainGraph.Cancellations;
    const visit = selection.visits[0];

    const haveCancelBefore = cancellations.before.find(c => c.TrainId == visit.train.Id && c.StationId == visit.stop.Station.Id);
    const haveCancelAfter = cancellations.after.find(c => c.TrainId == visit.train.Id && c.StationId == visit.stop.Station.Id);

    const cancelBeforeStyle = haveCancelBefore ? "canvasiconselected" : "canvasicon";
    const cancelAfterStyle = haveCancelAfter ? "canvasiconselected" : "canvasicon";

    const onClickCancelBefore = useCallback(() => {
        if (haveCancelBefore) {
            log.info({ message: `Fjernet innstilling av ${visit.train.Number} før ${visit.stop.Station.Name}.` });
            overrideSetters.deleteOverride(haveCancelBefore)
        }
        else {
            log.info({ message: `Opprettet innstilling av ${visit.train.Number} før ${visit.stop.Station.Name}.` });
            overrideSetters.newOverride({
                Type: "CancelTrainBefore",
                TrainId: visit.train.Id,
                StationId: visit.stop.Station.Id,
            });
        }
    });
    const onClickCancelAfter = useCallback(() => {
        if (haveCancelAfter) {
            log.info({ message: `Fjernet innstilling av ${visit.train.Number} etter ${visit.stop.Station.Name}.` });
            overrideSetters.deleteOverride(haveCancelAfter);
        }
        else {
            log.info({ message: `Opprettet innstilling av ${visit.train.Number} etter ${visit.stop.Station.Name}.` });
            overrideSetters.newOverride({
                Type: "CancelTrainAfter",
                TrainId: visit.train.Id,
                StationId: visit.stop.Station.Id,
            });
        }
    });

    const hasArrivalTime = (trainGraph.TrainNotBefore.Arrives || [])
        .find(c => c.TrainId == visit.train.Id && c.StationId == visit.stop.Station.Id);

    const hasDepartureTime = (trainGraph.TrainNotBefore.Departs || [])
        .find(c => c.TrainId == visit.train.Id && c.StationId == visit.stop.Station.Id);

    const onClickArrival = () => {
        const data = hasArrivalTime || { TrainId: visit.train.Id, StationId: visit.stop.Station.Id, Time: visit.stop.AimedArrival_date };
        setSelection({ type: "setArrivalTime", data, visit, originalData: hasArrivalTime });
    };
    const arrivalStyle = hasArrivalTime ? "canvasiconselected" : "canvasicon";

    const onClickDeparture = () => {
        const data = hasDepartureTime || { TrainId: visit.train.Id, StationId: visit.stop.Station.Id, Time: visit.stop.AimedDeparture_date };
        setSelection({ type: "setDepartureTime", data, visit, originalData: hasDepartureTime });
    };
    const departureStyle = hasDepartureTime ? "canvasiconselected" : "canvasicon";

    const onClickFixMeeting = () => {
        setTrainFixMeetingMenu(visit.train.Id);
    };

    const cancelBeforeTitle = !haveCancelBefore ? "Innstill tog før stopp" : "Fjerne innstilling før stopp";
    const cancelAfterTitle = !haveCancelAfter ? "Innstill tog etter stopp" : "Fjerne innstilling etter stopp";

    return html`<b>${visit.train.Number} ${visit.stop.Station.Name}</b><br />
        <${MenuItem} icon="fas fa-step-backward" enabled="true" styles=${cancelBeforeStyle} onClick=${onClickCancelBefore} label=${cancelBeforeTitle} />
        <${MenuItem} icon="fas fa-step-forward" enabled="true" styles=${cancelAfterStyle} onClick=${onClickCancelAfter} label=${cancelAfterTitle} />
        <${MenuItem} icon="far fa-clock" enabled="true" styles=${arrivalStyle} onClick=${onClickArrival} label="Sett tidligste ankomst" />
        <${MenuItem} icon="far fa-clock" enabled="true" styles=${departureStyle} onClick=${onClickDeparture} label="Sett tidligste avgang" />
        <${MenuItem} icon="fas fa-lock" enabled="true" styles="canvasicon" onClick=${onClickFixMeeting} label="Velg møtested" />
        `;
}

function FixMeeting({ scales, selection, overrideSetters, fixedMeetings, log }) {
    const alreadyHaveMeeting = fixedMeetings.find(m => {
        if (m.StationId != selection.visits[0].stop.Station.Id) return false;
        return (m.TrainId == selection.visits[0].train.Id && m.OtherTrainId == selection.visits[1].train.Id) ||
            (m.TrainId == selection.visits[1].train.Id && m.OtherTrainId == selection.visits[0].train.Id);
    });
    const icon = alreadyHaveMeeting ? "fas fa-lock" : "fas fa-lock-open";
    const style = alreadyHaveMeeting ? "canvasiconselected" : "canvasicon";

    const v1 = selection.visits[0];
    const v2 = selection.visits[1];

    const onClick = useCallback(() => {
        if (alreadyHaveMeeting) {
            log.info({ message: `Fjernet tvunget møte mellom ${v1.train.Number} og ${v2.train.Number} ved ${v1.stop.Station.Name}.` });
            overrideSetters.deleteOverride({
                Type: "TrainsMeet",
                TrainId: alreadyHaveMeeting.TrainId,
                OtherTrainId: alreadyHaveMeeting.OtherTrainId,
                StationId: alreadyHaveMeeting.StationId,
            });
        } else {
            log.info({ message: `Opprettet tvunget møte mellom ${v1.train.Number} og ${v2.train.Number} ved ${v1.stop.Station.Name}.` });
            overrideSetters.newOverride({
                Type: "TrainsMeet",
                TrainId: v1.train.Id,
                OtherTrainId: v2.train.Id,
                StationId: v1.stop.Station.Id,
            });
        }
    });

    const pt = lerp2(v1.pt, v2.pt, 0.5);
    const menu = html`<b>${v1.train.Number}/${v2.train.Number} ${v1.stop.Station.Name}</b><br />
        <${MenuItem} icon=${icon} styles=${style} enabled="true" onClick=${onClick} label="Tog møtes her"/>`;
    const line = FixMeetingLine({ scales, visit1: v1, visit2: v2 });
    return [line, menu, pt];
}

function FixMeetingLine({ scales, visit1, visit2 }) {
    const x1 = scales.x(visit1.pt[0]);
    const x2 = scales.x(visit2.pt[0]);
    const y = scales.y(visit1.pt[1]);
    const line = html`<line style="stroke: orange; opacity: 0.7; stroke-dasharray: 1rem 0.5rem; stroke-width: 0.5rem;"
            x1=${x1} x2=${x2} y1=${y} y2=${y} />`;
    return line;
}

function ToolTip({ size, children, pt, side, clickable }) {
    if (!size || !pt) return;

    var xLocation;

    if (!side || side == "left") {
        const x = (size.width - (pt[0] - 20));
        xLocation = `right: ${x}px;`;
    } else if (side == "right") {
        const x = pt[0] + 20;
        xLocation = `left: ${x}px;`;
    }

    var yLocation;
    if (pt[1] > size.height / 2) {
        yLocation = `bottom: ${size.height - (pt[1] + 50)}px;`;
    } else {
        yLocation = `top: ${(pt[1] - 50)}px;`;
    }

    const pointerEvents = clickable ? "all" : "none";

    return html`
        <div class="visittooltip" style="position: absolute; ${xLocation} ${yLocation} padding: 1rem; border: 2px solid gray; background: white; pointer-events: ${pointerEvents};">
            ${children}
        </div>`;
}

function MenuOverUnder({ scales, children, pt, close }) {
    if (!scales || !pt) return;
    const x = scales.x(pt[0]);
    var topBottom = `bottom: ${(scales.sz.height - (scales.y(pt[1] + 0.4)))}px`;
    if (scales.sz && scales.y(pt[1]) < 0.5 * scales.sz.height) {
        topBottom = `top:  ${scales.y(pt[1] - 0.4)}px`;
    }

    return html`
        <div  style="position: absolute; left: ${x}px; ${topBottom}; pointer-events: none;" >
            <div class="menuover"  style="pointer-events: all; position: relative; left: -50%;  padding: 1rem; border: 2px solid gray; background: white; ">
                <div style="float:right; margin-left: 1rem;"><a href="#" onclick=${close}><i class="fas fa-times"></i></a></div>
                ${children}
            </div>
        </div>`;
}

function MenuItem({ icon, label, onClick, selected, enabled, styles, size }) {
    const sizePercent = (size || 1.5) * 100;
    const cls = selected ? " canvasiconselected" : (enabled ? "" : " icondisabled");
    return html`<div><a class="menuitem" onClick=${onClick} href="#">
                <i class="${"icon " + icon + cls} ${styles ? styles : ""}" style="font-size: ${sizePercent}%; margin:1rem;"></i>
                ${label}
            </a>
        </div>`
}


function StationToolTip({ trainGraph, station, close, log, overrideSetters, hideButtons }) {
    if (!station) return;
    const closeButton = close && html`<div style="float:right"><a href="#" onclick=${close}><i class="fas fa-times"></i></a></div>`;

    const tracks = {};

    const internalTracksId = station.InternalTracksId.split("-");
    const internalTracksLength = station.InternalTracksLength.split("-");
    const platformsId = station.PlatformsId.split("-");
    const platformsLength = station.PlatformsLength.split("-");

    for (var i = 0; i < internalTracksId.length; i++) {
        if (internalTracksId[i] == "") continue;
        tracks[internalTracksId[i]] = {};
        tracks[internalTracksId[i]].trackLength = internalTracksLength[i];
    }
    for (var i = 0; i < platformsId.length; i++) {
        if (platformsId[i] == "") continue;
        if (!tracks[platformsId[i]]) tracks[platformsId[i]] = {};
        tracks[platformsId[i]].platformLength = platformsLength[i];
    }

    function formatTrackLength(length) {
        if (!length) return "-";
        if (length == 0 || length == 1000) return "ubegrenset";
        return length;
    }

    const tracksTableRows = Object.keys(tracks).map(function (trackId) {
        const haveClosure = trainGraph.InternalTracksClosures.find(c => c.StationId == station.Id && c.TrackId == trackId);
        const closeStyle = haveClosure ? "canvasiconselected" : "canvasicon";

        const onClickClose = useCallback(() => {
            if (haveClosure) {
                log.info({ message: `Fjernet stenging av spor ${trackId} ved ${station.Name}.` });
                overrideSetters.deleteOverride(haveClosure)
            }
            else {
                log.info({ message: `Opprettet stenging av spor ${trackId} ved ${station.Name}.` });
                overrideSetters.newOverride({
                    Type: "InternalTrackClosure",
                    StationId: station.Id,
                    TrackId: trackId,
                });
            }
        });

        const label = !haveClosure ? "Steng spor" : "Fjern stenging";

        const closeTrackButton = hideButtons ? null : html`
            <${MenuItem} size=0.9 icon="far fa-window-close" enabled="true" styles=${closeStyle} onClick=${onClickClose} label=${label} />
        `;

        return html`<tr><td class="tooltipcell">${trackId}</td><td class="tooltipcell">${formatTrackLength(tracks[trackId].trackLength)}</td><td class="tooltipcell">${formatTrackLength(tracks[trackId].platformLength)}</td><td class="tooltipcell">${closeTrackButton}</td></tr>`;
    });
    const closedTitle = hideButtons ? "" : "Stenging";
    const tracksTable = html`<table class="tooltiptable"><tr><th class="tooltipcell">Spor</th><th class="tooltipcell">Sporlengde</th><th class="tooltipcell">Platformlengde</th><th class="tooltipcell">${closedTitle}</th></tr>${tracksTableRows}</table>`;

    return html`${closeButton}<b>${station.Name}</b> (Km.${station.Km})<br /><p>${tracksTable}</p>`;
}

export function VisitToolTip({ hoveredVisit }) {
    if (!hoveredVisit || hoveredVisit.type != "visit") return;

    const station = hoveredVisit.stop.Station.Name;
    const format = (d) => displayTimeMinutes(new Date(d));

    const table = [];
    const lines = [
        ["Ruteplan", hoveredVisit.stop.AimedArrival_date, hoveredVisit.stop.AimedDeparture_date],
        ["Faktisk", hoveredVisit.stop.ActualArrival_date, hoveredVisit.stop.ActualDeparture_date],
        ["Beregnet", hoveredVisit.stop.PlannedArrival_date, hoveredVisit.stop.PlannedDeparture_date]
    ];

    for (const line of lines) {
        const [name, arrival, departure] = line;
        var arr = arrival && format(arrival);
        var dep = departure && format(departure);
        if (arr || dep) {
            table.push([name, arr, "/", dep]);
        }
    }

    return html`<b> ${station} - Tog ${hoveredVisit.train.Number}</b><br />
        <table class="tooltiptable">${table.map(cells => html`
            <tr>
                ${cells.map(cell => html`<td class="tooltipcell">${cell}</td>`)}
            </tr>`)}
        </table>`;
}

