import { html } from 'htm/preact';
import { useState, useCallback, useEffect, useRef, useMemo } from 'preact/hooks';
import { memo } from 'preact/compat';
import { sendFeedback } from '@/service/backend';
import { Spinner } from '@/utils/utils';

declare var html2canvas: any; // TODO remove old-style <script> tag in index.html and use npm package instead.

const Window = ({ close, children, cls }: any) => html`<div class="floatingoverlaywindow overlaywindow ${cls && cls || ""}" style="padding: 4rem;">
    <div style="float:right; margin-left: 1rem;"><a href="#" onclick=${close}><i class="fas fa-times"></i></a></div>
    ${children}
        </div >`;

export function FeedbackMenu({ appState, feedbackMenu, setFeedbackMenu }: any) {
    const [loading, setLoading] = useState(false);
    if (feedbackMenu === null) return;
    /// Feedback object should consist of strings in `Source`, `Sender`, `Topic`, `Contents`, canvas in `screenshot`.

    if (!feedbackMenu.screenshot) {
        html2canvas(document.body).then((canvas: any) => {
            setFeedbackMenu({ ...feedbackMenu, screenshot: canvas });
        });
        return;
    }

    const inputEnabled = !loading;
    const gettext = appState.gettext;
    const close = () => { setFeedbackMenu(null); };
    const setSender = (ev: any) => { setFeedbackMenu({ ...feedbackMenu, Sender: ev.target.value }) };
    const setContents = (ev: any) => { setFeedbackMenu({ ...feedbackMenu, Contents: ev.target.value }) };
    const clickSend = () => {
        setLoading(true);
        sendFeedback(appState, feedbackMenu).then((r: any) => {
            if (r.Ok) {
                setFeedbackMenu({ response: `${gettext("Thank you for submitting feedback")}.` })
            } else {
                throw new Error(r.Error);
            }
        }).catch((r: any) => {
            setFeedbackMenu({ response: `${gettext("HTTP error")}: ${r.message}.` })
        }).finally(() => { setLoading(false); });
    };

    if (feedbackMenu.response) {
        return html`<${Window} close=${close} cls="feedbackwindow">
                <p>${feedbackMenu.response}</p>
                <input enabled=${inputEnabled} style="margin: 0.5rem;" type="button" class="button" value="OK" onClick=${close} />
            </${Window}>`;
    } else {
        return html`<${Window} close=${close} cls="feedbackwindow">
                <h1>${gettext("Send feedback to the developers")}</h1>
                <p>${gettext("Name/email")}:<br /><input enabled=${inputEnabled} type="text" style="width: 20rem;" value=${feedbackMenu.Sender} onInput=${setSender} /></p>
                <p>${gettext("Comment")}:<br /><textarea enabled=${inputEnabled}  style="width: 40rem; height: 20rem;" value=${feedbackMenu.Contents} onInput=${setContents} /></p>
                <p><input enabled=${inputEnabled} style="margin: 0.5rem;" type="button" class="button button-primary" value="Send" onClick=${clickSend} /></p>
               ${loading && html`<${Spinner} />`}<br />
                <p>${gettext("Attached screenshot")}:<br /><${Screenshot} screenshot=${feedbackMenu.screenshot} /></p>
            </${Window}>`;
    }
}

const Screenshot = memo(({ screenshot }: { screenshot: any }) => {
    const dataUrl = screenshot.toDataURL();
    return (
        <img src={dataUrl} style="max-width: 50rem;" />
    );
});
