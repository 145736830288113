import { useCallback, useState } from "preact/hooks";

/*
* Returns a useState pair consisting of an empty object and a function that changes the 
* object to a new empty object. The empty object can be used as a dependency in hooks,
* and the function can be called to trigger updates in those dependent hooks.
*/
export function useTrigger(): [{}, (() => void)] {
    const [state, setState] = useState({});
    return [state, useCallback(() => setState({}), [setState])];
}

// const [resetEvent, triggerReset] = useEvent();

