// This type represents the exact JSON structure produced from the web server 
// from the TrainGraphData WPF contract in the C# backend service.
// Only relevant fields are included.

import { StationRouting, StationRoutingSolverStatus, StationRoutingStation, StationRoutingStop, StationRoutingTrain } from "@/models/stationroutingdata";
import { TrainGraphData } from "@/models/traingraphdata";

type RawDate = {
    InUtc: string,
};

type RawTrainGraphData = {
    Now: RawDate,
    RailwayNetworkName: string,
    LastUpdateTime: RawDate,
    Stations: RawStationInfo[],
    Tracks: RawTrack[],
    StationRouting: RawStationRouting | null,
    Trains: RawTrain[],
}

type RawStationInfo = {
    Index: number,
    Id: string,
    Name: string,
    Km: number,
    LengthOfLongestTrack: number,
    PlatformsId: string,
    PlatformsLength: string,
    InternalTracksId: string,
    InternalTracksLength: string,
    Type: string,
};

type RawTrack = {
    Id: string,
    FromStationId: string,
    ToStationId: string,
    Name: String,
    Km: number,
    IsDoubleTrack: boolean,
};

type RawTrain = {
    Number: string,
    Id: String,
    IsCancelled: boolean,
    Stops: RawStop[],
    HasGoneSilent: boolean,
    KindTypeAndOperator: string,
    ServiceName: string,
    Length: number,
}

// NOTE: there is more information available in the raw JSON, 
// add fields here from the C# data contracts as necessary.
type RawStop = {
    StationIndex: number,
    StationId: number,
    AimedArrival: RawDate | null,
    AimedDeparture: RawDate | null,
    AimedTrainId: string | null,
    ActualArrival: RawDate | null,
    ActualDeparture: RawDate | null,
    ActualTrainId: string | null,
    PlannedArrival: RawDate | null,
    PlannedDeparture: RawDate | null,
    PlannedTrainId: string | null,
    IsCancelled: boolean,
};

type RawStationRouting = {
    SolverStatus: RawStationRoutingSolverStatus,
    Stations: RawStationRoutingStation[] | null,
    Trains: RawStationRoutingTrain[] | null,
};

type RawStationRoutingSolverStatus = {
    LastSolveFinished: RawDate,
    Successful: boolean,
    Warnings: string[],
    Errors: string[],
    SolverLog: string[],
    CurrentSolveStatus :string | null,
};

type RawStationRoutingStation = {
    Id: string,
    Name: string,
    Tracks: RawStationRoutingTrack[],
};

type RawStationRoutingTrack = {
    Name: string,
};

type RawStationRoutingTrain = {
    Id: string,
    Number: string,
    Stops: RawStationRoutingStop[],
    OriginStationId : string|null,
    DestinationStationId : string|null,
    ServiceName: string | null,
    KindTypeAndOperator: string | null,
    RollingStocks: RawRollingStock[] | null,
};

type RawRollingStock = {
    FromStationId: string | null,
    ToStationId: string | null,
    Length: number | null,
    Weight: number | null,
    Type: string | null,
    TractionUnits: string[] | null,
    WagonUnits: string[] | null,
};

type RawStationRoutingStop = {
    StationIdx: number,
    StationId: string,
    EntryLine: string | null,
    ExitLine: string | null,
    EntryWaitTime: number,
    ExitWaitTime: number,
    AimedTrackId: string | null,
    PlannedTrackId: string | null,
    ActualTrackId: string | null,
    PlannedStationEntry: RawDate,
    PlannedStationExit: RawDate,
    PlannedPlatformArrival: RawDate,
    PlannedPlatformDeparture: RawDate,
    AimedStationEntry: RawDate,
    AimedStationExit: RawDate,
    AimedPlatformArrival: RawDate,
    AimedPlatformDeparture: RawDate,
    ActualStationEntry: RawDate | null,
    ActualStationExit: RawDate | null,
    ActualPlatformArrival: RawDate | null,
    ActualPlatformDeparture: RawDate | null,
    Routes: RawRoute[],
    Lateness: RawLateness,
};

type RawLateness = {
    OriginalLateness : number,
    EstimatedLateness : number,
    OriginalLatenessStation : string | null,
    OriginalLatenessStationDeparture: boolean,
};

type RawRoute = {
    RouteName: string,
    PlannedTime: RawDate,
};


// Interpret JSON field as date.
export function parseMaybeDate(value: any): number | null {
    // If the format is "\/Date(1239018869048)\/" (ASP.net output)
    // then parse that, else let the built-in Date.parse handle it.
    if (value && value.startsWith && value.startsWith("\/Date(")) {
        return new Date(parseInt(value.substr(6))).getTime();
    }
    if (value && value.InUtc && value.InUtc.startsWith && value.InUtc.startsWith("\/Date(")) {
        return new Date(parseInt(value.InUtc.substr(6))).getTime();
    }

    // try standard JS date parsing inside .InUtc
    if (value && value.InUtc) {
        const tryParse = Date.parse(value.InUtc);
        return !isNaN(tryParse) ? tryParse : null;
    } else {
        const tryParse = Date.parse(value);
        return !isNaN(tryParse) ? tryParse : null;
    }
}

export function parseDate(value: any): number {
    return parseMaybeDate(value) ?? throwExpression("Date parse error");
}

function throwExpression(errorMessage: string): never {
    throw new Error(errorMessage);
}

export function parseTrainGraphData(json: any): TrainGraphData {
    // Assume that the json data has the structure of RawTrainGraphData.
    const trainGraphData = json as RawTrainGraphData;

    return {
        Now: parseDate(trainGraphData.Now),
        RailwayNetworkName: trainGraphData.RailwayNetworkName,
        Stations: trainGraphData.Stations,
        Tracks: trainGraphData.Tracks,
        StationRouting: trainGraphData.StationRouting != null ? parseStationRouting(trainGraphData.StationRouting) : null,
        Trains: trainGraphData.Trains,
    }
}

export function parseStationRouting(stationRouting: RawStationRouting): StationRouting {
    const SolverStatus: StationRoutingSolverStatus = {
        ...stationRouting.SolverStatus,
        LastSolveFinished: parseDate(stationRouting.SolverStatus.LastSolveFinished),
    };

    return {
        SolverStatus: SolverStatus,
        Stations: stationRouting.Stations?.map(s => parseStationRoutingStation(s)) ?? [],
        Trains: stationRouting.Trains?.map(t => parseStationRoutingTrain(t)) ?? [],
    };
}

function parseStationRoutingStation(station: RawStationRoutingStation): StationRoutingStation {
    return {
        ...station,
        TrackNames: station.Tracks.map(t => t.Name),
    };
}

function parseStationRoutingTrain(train: RawStationRoutingTrain): StationRoutingTrain {
    return {
        ...train,
        Stops: train.Stops.map(s => parseStationRoutingStop(s)),
    };
}

function parseStationRoutingStop(stop: RawStationRoutingStop): StationRoutingStop {
    return {
        ...stop,
        PlannedTrackId: stop.PlannedTrackId ?? throwExpression("No track ID assigned to train stop"),
        AimedPlatformArrival: parseDate(stop.AimedPlatformArrival),
        AimedPlatformDeparture: parseDate(stop.AimedPlatformDeparture),
        AimedStationEntry: parseDate(stop.AimedStationEntry),
        AimedStationExit: parseDate(stop.AimedStationExit),
        PlannedPlatformArrival: parseDate(stop.PlannedPlatformArrival),
        PlannedPlatformDeparture: parseDate(stop.PlannedPlatformDeparture),
        PlannedStationEntry: parseDate(stop.PlannedStationEntry),
        PlannedStationExit: parseDate(stop.PlannedStationExit),
        ActualPlatformArrival: parseMaybeDate(stop.ActualPlatformArrival),
        ActualPlatformDeparture: parseMaybeDate(stop.ActualPlatformDeparture),
        ActualStationEntry: parseMaybeDate(stop.ActualStationEntry),
        ActualStationExit: parseMaybeDate(stop.ActualStationExit),
        Routes: stop.Routes.map(r => ({ RouteName: r.RouteName, PlannedTime: parseDate(r.PlannedTime), })),
    };
}