
export type StationRouting = {
    SolverStatus: StationRoutingSolverStatus,
    Stations: StationRoutingStation[],
    Trains: StationRoutingTrain[],
};

export const defaultStationRoutingData :StationRouting = {
    SolverStatus: {
        LastSolveFinished: 0,
        Errors: ["No station routing result is available yet."],
        Successful: false,
        SolverLog: [],
        Warnings: [],
        CurrentSolveStatus: "Not available.",
    },
    Stations: [],
    Trains: [],
};

export type StationRoutingSolverStatus = {
    LastSolveFinished: number,
    Successful: boolean,
    Warnings: string[],
    Errors: string[],
    SolverLog: string[],
    CurrentSolveStatus :string | null,
};

export type StationRoutingStation = {
    Id: string,
    Name: string,
    TrackNames: string[],
};

export type StationRoutingTrain = {
    Id: string,
    Number: string,
    Stops: StationRoutingStop[],
    OriginStationId : string|null,
    DestinationStationId : string|null,
    ServiceName: string | null,
    KindTypeAndOperator: string | null,
    RollingStocks: RollingStock[] | null,
};

export type StationRoutingStop = {
    StationIdx: number,
    StationId: string,
    EntryLine: string | null,
    ExitLine: string | null,
    EntryWaitTime: number,
    ExitWaitTime: number,
    AimedTrackId: string | null,
    ActualTrackId: string | null,
    PlannedTrackId: string,
    PlannedStationEntry: number,
    PlannedStationExit: number,
    PlannedPlatformArrival: number,
    PlannedPlatformDeparture: number,
    AimedStationEntry: number,
    AimedStationExit: number,
    AimedPlatformArrival: number,
    AimedPlatformDeparture: number,
    ActualStationEntry: number | null,
    ActualStationExit: number | null,
    ActualPlatformArrival: number | null,
    ActualPlatformDeparture: number | null,
    Routes: Route[],
    Lateness :Lateness,
};

export type Lateness = {
    OriginalLateness : number,
    EstimatedLateness : number,
    OriginalLatenessStation : string | null,
    OriginalLatenessStationDeparture: boolean,
};

export type Route = {
    RouteName: string,
    PlannedTime: number,
};

export type RollingStock = {
    FromStationId: string | null,
    ToStationId: string | null,
    Length: number | null,
    Weight: number | null,
    Type: string | null,
    TractionUnits: string[] | null,
    WagonUnits: string[] | null,
};