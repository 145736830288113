import { StationRouting } from "./stationroutingdata";

export type TrainGraphData = {
    Now :number,
    RailwayNetworkName :string,
    Stations :any[],
    Tracks :any[],
    StationRouting :StationRouting | null,
    Trains :any[],    
};

/*
* Temporary, incomplete version of TrainGraphData. To be removed.
*/
export interface HasStationRouting {
    StationRouting: StationRouting,
}


export function isExitingLine(train: any, i: number): boolean {
    return (i + 1 < train.Stops.length && train.Stops[i + 1].YCoord === undefined) ||
        (i + 1 == train.Stops.length && train.RealDestinationId && train.Stops[i].StationId != train.RealDestinationId);
}

export function isEnteringLine(train: any, i: number): boolean {
    return (i > 0 && train.Stops[i - 1].YCoord === undefined) ||
        (i == 0 && train.RealOriginId && train.Stops[i].StationId != train.RealOriginId);
}
